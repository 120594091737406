import queryString from "query-string";
import { parseErrorResponse, request } from "./request";

const getTransactions = (req) =>
  new Promise((resolve, reject) => {
    const query = queryString.stringify(req.query);
    request()
      .get(`transactions?${query}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const withdraw = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post(`transactions/withdraw`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const TransactionService = {
  getTransactions,
  withdraw,
};

export default TransactionService;
