import React from "react";
import logo from "assets/images/logo/logo-tosi.png";
import { useNavigate } from "react-router-dom";
import { ROUTERS } from "config/routes";

export default function AuthLayout({ slogan, children, isRegister }) {
  const navigate = useNavigate();

  const checkScreenHeight = window.screen.height;
  let screen = "";
  if (checkScreenHeight <= 950) {
    screen = "h-screen";
    // screen = "h-[950px]";
  } else {
    screen = "h-screen";
  }

  return (
    <div className="layout-wrapper">
      <div
        className={`main-wrapper w-full primary-auth-gradient ${
          !isRegister ? screen : "xl:h-screen h-full xl:py-0 py-12"
        }`}
      >
        <div className="flex w-full h-full">
          <div className="xl:flex hidden w-3/5 h-full p-[70px] flex-col justify-between ">
            <div
              className="logo flex items-center"
              onClick={(_) => navigate(ROUTERS.LOGIN)}
            >
              <img src={logo} alt="logo" width={40} />
              <p className="ml-[6px] text-[18px] text-white font-bold">
                TOSIBANK
              </p>
            </div>
            <div className="thumbnail flex justify-center">
              <img src={'assets/images/auth-image.png'} alt="login-thumb" />
            </div>
            <div className="article w-[600px]">
              <p className="text-[50px] font-bold leading-[72px] text-white">
                {slogan}
              </p>
            </div>
          </div>
          <div className="flex-1 flex justify-center items-center 2xl:pr-28 xl:pr-11">
            {children && children}
          </div>
        </div>
      </div>
    </div>
  );
}
