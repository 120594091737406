import React from "react";
import Saved from "../components/Saved";

export default function SavedPage() {
  return (
    <>
      <Saved />
    </>
  );
}
