/* eslint-disable react-hooks/exhaustive-deps */
import { IconCommon } from "components/Helpers/Icons";
import { GlobalContext } from "contexts/GlobalContext";
import { useContext, useMemo } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { copyText, formatNumber } from "utils/common";
import cryptoCoin from "../../assets/images/crypto-coin.png";
import missile from "../../assets/images/missile.png";
import profileImg from "../../assets/images/profile-pic.jpg";
import profitImage from "../../assets/images/profit-image.png";
import profitShield from "../../assets/images/profit-shield.png";
import tosiCoin from "../../assets/images/tosi_coin.png";
import Layout from "../Partials/Layout";
import LevelList from "./LevelList";

export default function Home() {
  const domain = window.location.origin;
  const { parameter, system, levelInvest, auth, usersGroup } =
    useContext(GlobalContext);
  const { count } = parameter;
  const amountGroup = useMemo(() => {
    if (auth && usersGroup && usersGroup.length > 0) {
      return usersGroup.filter((u) => u.referred_by === auth.refer_code).length;
    }
    return 0;
  }, [auth, usersGroup]);

  const totalIncome = useMemo(() => {
    if (parameter.count) {
      return formatNumber((parameter.count.salary || 0) + (parameter.count.commission || 0) + (parameter.count.profit || 0));
    }
    return 0;
  }, [parameter.count]);

  const memberCategories = [
    {
      id: "1",
      name: "Your level",
      value: parameter.level || "MEMBER",
      icon: `assets/images/levels/${String(
        parameter.level || "member"
      ).toLowerCase()}.png`,
    },
    {
      id: "2",
      name: "Group",
      value: amountGroup,
      icon: "assets/images/member1.svg",
    },
    // {
    //   id: "3",
    //   name: "Total sales",
    //   value: formatNumber(parameter?.count.revenue_group || 0),
    //   icon: "assets/images/total_sales.svg",
    //   unit: system.token,
    // },
  ];
  const investCategories = [
    {
      id: "1",
      name: "Total investment",
      isShowLogo: true,
      value: formatNumber(count.invest || 0, 2),
      icon: levelInvest?.icon,
    },
    {
      id: "2",
      name: "Daily profit",
      value: formatNumber(count.daily_profit || 0, 2),
      icon: "assets/images/profit_day.svg",
    },
    {
      id: "3",
      name: "Total payback",
      value: formatNumber(count.total_payback || 0, 2),
      icon: "assets/images/total_payback.svg",
    },
  ];

  const handleCopy = (value) => {
    copyText(value);
    toast.success("Copied");
  };

  return (
    <Layout>
      <div className="">
        <div className="lg:mb-16 mb-10 flex justify-between">
          <div className="flex flex-col justify-center lg:w-fit w-full">
            <div className="mb-9 lg:text-start text-center">
              <p className="font-bold sm:text-5xl md:text-6xl 2xl:text-[66px] text-2xl text-white mb-2 lg:text-start text-center text-nowrap">Welcome to TOSIBANK</p>
              <span className="text-[18px] font-thin tracking-wide text-gray-60">
                ID : {auth?.refer_code}
              </span>
            </div>

            {/* profile */}
            <div className="flex items-center space-x-3.5 lg:justify-start justify-center">
              {/* profile-image */}
              <div className="lg:w-[80px] lg:h-[80px] h-[62px] w-[62px] rounded-full overflow-hidden">
                <img
                  src={auth?.avatar || profileImg}
                  alt="profile"
                  className="w-full h-full"
                />
              </div>
              <div className="">
                <h1 className="2xl:text-[50px] md:text-3xl font-bold text-dark-gray dark:text-white 2xl:leading-[4rem]">
                  {auth?.full_name}
                </h1>
                <p className="lg:text-xs text-sm text-athens-gray">
                  {auth?.email}
                </p>
              </div>
            </div>
          </div>

          <div className="2xl:mr-10 mr-10 lg:block hidden">
            <img
              src={tosiCoin}
              alt=""
              className="2xl:w-[340px] 2xl:h-[340px] w-[260px] h-[260px]"
            />
          </div>
        </div>

        <div className="flex lg:flex-row flex-col gap-8">
          <div className="lg:w-3/12 flex flex-col justify-between gap-2">
            <div className="flex justify-start items-center gap-2">
              <div className="min-w-[80px] rounded-full border border-t-[2px] border-white-12 p-6 text-center">
                <span className="text-2xl text-white text-center">{amountGroup}</span>
              </div>
              <div>
                <span className="text-white">Groups</span>
              </div>
            </div>
            <div className="bg-blue-9 rounded-[28px] p-[22px] border border-t-[2px] border-white-12 grow">
              <div className="mb-4">
                <span className="text-white">Your level</span>
              </div>
              <LevelList currentLevel={parameter.level || "member"} />
            </div>
          </div>

          <div className="lg:w-5/12 bg-blue-9 rounded-[28px]  border border-t-[2px] border-white-12 flex flex-col justify-between">
            <div className="p-[22px] bg-blue-dark rounded-[28px]">
              <div className="flex justify-between mb-2">
                <span className="text-white">Daily profit</span>
                <p className="text-yellow-supernova font-medium">
                  <span className="text-2xl mr-1">{formatNumber(count.daily_profit || 0, 2)}</span>
                  <span className="text-xs">TSIB </span>
                </p>
              </div>
              <div className="rounded-[14px]">
                <img src={profitImage} alt="profit" className="w-full max-h-[100px]" />
              </div>
            </div>

            <div className="px-[22px] pt-3 pb-[30px]">
              <div className="flex justify-between mb-3">
                <img src={profitShield} alt="shield" className="w-[45px] h-[45px]" />
                <Link to="/member" className="rounded-full border border-white-12 px-4 py-2 bg-white">
                  <span className="text-blue-15 font-semibold	">
                    Tree system
                  </span>
                </Link>
              </div>

              <div className="flex sm:flex-row flex-col justify-between sm:gap-6">
                <span className="lg:max-w-[160px] text-white">
                  Use the below link to invite your friends:
                </span>
                <div className="flex sm:flex-row flex-col lg:flex-col 2xl:flex-row lg:items-end items-start">
                  <p className="url-share mr-1 text-gray-60 text-lg truncate w-full max-w-[250px]">{`${domain}/signup?referred_by=${auth?.refer_code}`}</p>
                  <div className="rounded-full bg-[#FCB711] p-3 cursor-pointer">
                    <IconCommon
                      name="copy-dark.svg"
                      width={20}
                      height={20}
                      onClick={(_) =>
                        handleCopy(`${domain}/signup?referred_by=${auth?.refer_code}`)
                      }
                    />
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div className="lg:w-4/12 bg-blue-9 rounded-[28px] border border-t-[2px] border-white-12">
            <div className="p-[22px] bg-blue-dark rounded-[28px] mb-3">
              <div className="flex justify-between mb-3">
                <img src={missile} alt="shield" className="w-[45px] h-[45px]" />
                <Link to="/invest" className="rounded-full border border-white-12 px-4 py-2 bg-white">
                  <span className="text-blue-15 font-semibold	">
                    Invest
                  </span>
                </Link>
              </div>
              <div className="flex flex-col">
                <span className="text-white">Total investment</span>
                <p className="text-yellow-supernova font-medium">
                  <span className="text-2xl mr-1">{formatNumber(count.invest || 0, 2)}</span>
                  <span className="text-xs">TSIB </span>
                </p>
              </div>
            </div>

            <div className="px-[22px] pt-3 pb-[30px]">
              <div className="flex justify-between mb-3">
                <img src={cryptoCoin} alt="shield" className="w-[45px] h-[45px]" />
                <Link to="/transactions" className="rounded-full border border-white-12 px-4 py-2 bg-white">
                  <span className="text-blue-15 font-semibold	">
                    History
                  </span>
                </Link>
              </div>

              <div className="flex justify-between 2xl:flex-row lg:flex-col sm:flex-row flex-col">
                <div className="flex flex-col 2xl:mb-0 lg:mb-3 sm:mb-0 mb-3">
                  <span className="text-white">Total income</span>
                  <p className="text-yellow-supernova font-medium">
                    <span className="text-2xl mr-1">{ totalIncome }</span>
                    <span className="text-xs">TSIB </span>
                  </p>
                </div>

                <div className="flex flex-col">
                  <span className="text-white">Total payback</span>
                  <p className="text-yellow-supernova font-medium">
                    <span className="text-2xl mr-1">{formatNumber(count.total_payback || 0, 2)}</span>
                    <span className="text-xs">TSIB </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
