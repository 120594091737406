/* eslint-disable import/order */
import React, { useState } from "react";
import Button from "components/Helpers/Button";

import titleShape from "assets/images/shape/title-shape-two.svg";
import AuthLayout from "../AuthLayout";
import ThankYou from "../ThankYou";
import InputCom from "components/Helpers/Inputs/InputCom";
import useQuery from "hooks/useQuery";
import { toast } from "react-toastify";
import AuthService from "services/auth";

export default function UpdatePassword() {
  const [updated, setUpdated] = useState(false);
  const [message, setMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    password: "",
    confirmPassword: "",
  });

  const { otp, token } = useQuery();

  const updatePassword = async () => {
    try {
      const { confirmPassword, password } = state;
      if (confirmPassword !== password) {
        toast.error("Password not match");
      }
      await AuthService.resetPassword({
        body: {
          password,
          otp,
          token,
        },
      });
      setLoading(true);
      setUpdated(!updated);
      setTimeout(() => {
        setMessage(!message);
      }, 100);
    } catch (error) {
      toast.error(error.errors);
    }
  };

  return (
    <>
      <AuthLayout slogan="Welcome to  Tosi Bank">
        {updated === false ? (
          <div className="content-wrapper update-password-section xl:bg-white dark:bg-dark-white   w-full 2xl:h-[818px] xl:h-[600px] sm:w-auto sm:px-[70px] px-5 2xl:px-[100px] rounded-xl flex flex-col justify-center">
            <div>
              <div className="title-area relative flex flex-col justify-center items-center mb-7">
                <h1 className="sm:text-5xl text-4xl font-bold leading-[74px]   text-dark-gray dark:text-white">
                  Update Password
                </h1>
                {/* w-[341px] absolute top-14 left-12 */}
                <div className="shape sm:w-[341px] w-[270px] -mt-5 sm:-mt-1 ml-5">
                  <img src={titleShape} alt="shape" />
                </div>
              </div>
              <div className="input-area">
                {/* <div className="input-item mb-5">
                  <InputCom
                    placeholder="*********"
                    label="Old Password"
                    name="password"
                    type="password"
                    iconName="password"
                  />
                </div> */}
                <div className="input-item mb-5">
                  <InputCom
                    placeholder="*********"
                    label="New Password"
                    name="password"
                    type="password"
                    iconName="password"
                    inputHandler={(e) =>
                      setState((prev) => ({
                        ...prev,
                        password: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="input-item mb-5">
                  <InputCom
                    placeholder="*********"
                    label="Re-enter Password"
                    name="password"
                    type="password"
                    iconName="password"
                    inputHandler={(e) =>
                      setState((prev) => ({
                        ...prev,
                        confirmPassword: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="signin-area mb-3.5">
                  <Button
                    loading={loading}
                    onClick={updatePassword}
                    className="w-full rounded-[50px] mb-5 h-[58px] text-xl text-white font-bold flex justify-center bg-purple items-center"
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <ThankYou className={`thankyou-section ${message ? "active" : ""}`} />
        )}
      </AuthLayout>
    </>
  );
}
