import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

// const firebaseConfig = {
//   apiKey: "AIzaSyA6oJeL15L5LBAqECGsb6swPXIKxb5Dmuw",
//   authDomain: "tosi-77c4a.firebaseapp.com",
//   projectId: "tosi-77c4a",
//   storageBucket: "tosi-77c4a.appspot.com",
//   messagingSenderId: "831714523148",
//   appId: "1:831714523148:web:bfc59dd91b0e4d4f93d629",
//   measurementId: "G-5N4CEB7XWW",
// };

// const firebaseConfig = {
//   apiKey: "AIzaSyB8f4fJd74GZUYdMWcvmbQ1e7PbD41eoSQ",
//   authDomain: "fir-tosi-bank.firebaseapp.com",
//   projectId: "fir-tosi-bank",
//   storageBucket: "fir-tosi-bank.appspot.com",
//   messagingSenderId: "798214743748",
//   appId: "1:798214743748:web:8f966b6aad517196620b91",
//   measurementId: "G-QLEWDJ6VYD",
// };

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

firebase.initializeApp(firebaseConfig);

export default firebase;
