import { IconPackage } from "components/Helpers/Icons";
import { GlobalContext } from "contexts/GlobalContext";
import { useContext } from "react";

export default function Package({ name, percent, icon, value }) {
  const { system } = useContext(GlobalContext);

  return (
    <div className="relative mt-10">
      <div className="absolute sm:-left-[20px] -top-8 w-[70px] h-[70px]">
        {<IconPackage url={icon} />}
      </div>
      <div className="py-8 px-4 font-bold text-center dark:text-white rounded-[28px] bg-blue-9">
        <div className="mb-10">
          <p className="text-white text-[30px] font-semibold">{percent}%</p>
          <p className="text-gray-60 text-sm font-medium">Direct commission</p>
        </div>
        <p className="text-yellow-supernova font-bold">
          <span className="text-[28px] mr-1">{value}</span>
          <span className="text-sm">{system.token}</span>
        </p>
      </div>
    </div>
  );
}
