import queryString from "query-string";
import { request, parseErrorResponse } from "./request";

const get = () =>
  new Promise((resolve, reject) => {
    request()
      .get("kycs")
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const verify = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post(`kycs`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const KycService = {
  get,
  verify,
};

export default KycService;
