/* eslint-disable react/no-array-index-key */
/* eslint-disable react/button-has-type */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
import { GlobalContext } from "contexts/GlobalContext";
import { useCallback, useContext, useEffect, useState } from "react";
import TransactionService from "services/transaction";
import { formatDate, getTextByNumber } from "utils/common";
import { TRANSACTION_TYPE, WALLET_STYLE_STATUS } from "utils/constant";
import SelectBox from "../Helpers/SelectBox";
import SectionTitle from "components/Helpers/SectionTitle";

export default function HistoryTable({ className }) {
  const filterCategories = [
    "All",
    "Deposit",
    "Withdraw",
    "Commission",
    "Profit",
    "Invest",
    "Payback",
    "Salary",
  ];

  const { system } = useContext(GlobalContext);
  const [selectedCategory, setSelectedCategory] = useState(filterCategories[0]);

  const [transaction, setTransaction] = useState({
    docs: [],
    hasNextPage: false,
    hasPrevPage: false,
  });
  const [lazyParams, setLazyParams] = useState({
    page: 1,
    limit: 10,
  });

  useEffect(() => {
    getTransactions(selectedCategory);
  }, [lazyParams]);

  const filterHander = (value) => {
    setSelectedCategory(value);
    getTransactions(value);
  };

  const getTransactions = useCallback(async (filterActive) => {
    try {
      const res = await TransactionService.getTransactions({
        query: {
          ...lazyParams,
          type:
            filterActive === "All"
              ? [
                "withdraw",
                "deposit",
                "profit",
                "commission",
                "invest",
                "transfer",
                "payback",
                "salary",
              ]
              : String(filterActive).toLowerCase(),
        },
      });
      setTransaction(res);
    } catch (error) { }
  });

  const nextPage = () => {
    setLazyParams((p) => ({
      ...p,
      page: p.page + 1,
    }));
  };

  const prevPage = () => {
    setLazyParams((p) => ({
      ...p,
      page: p.page - 1,
    }));
  };
  return (
    <div
      className={`update-table  ${className || ""
        }`}
    >
      <div className="header w-full flex justify-between items-center mb-8 flex-col sm:flex-row">
        <SectionTitle name="Transactions History" />
        <SelectBox
          action={filterHander}
          datas={filterCategories}
          className="Update-table-dropdown bg-white rounded-full px-5 py-3 min-w-[180px]"
          contentBodyClasses="w-auto min-w-max"
        />
      </div>
      <div className="relative w-full overflow-x-auto w-full p-5 bg-white dark:bg-blue-9   overflow-hidden rounded-[28px] section-shadow min-h-[520px]">
        <table className="w-full text-sm text-gray-500 dark:text-gray-400 border-spacing-y-2 border-separate tracking-wide">
          <thead>
            <tr className="text-base text-thin-light-gray whitespace-nowrap border-b dark:border-[#5356fb29]  default-border-b dark:border-[#5356fb29] ottom ">
              <th className="p-3 text-center hidden md:block">#</th>
              <th className="p-3 ">Transaction</th>
              <th className="p-3 text-center">Status</th>
              <th className="p-3">Amount</th>
              <th className="p-3 text-center">Time</th>
            </tr>

          </thead>
          <tbody>
            {transaction.docs.map((tran, key) => (
              <tr
                key={key}
                className="text-center"
              >
                <td className="hidden md:flex px-4 py-2 bg-gray-purple h-[84px] justify-center items-center rounded-l-[14px]">
                  <p className="font-bold text-sm text-dark-gray dark:text-white whitespace-nowrap">
                    {getTextByNumber(tran._id, 30)}
                  </p>
                </td>
                <td className="px-4 py-2 bg-gray-purple h-[84px]">
                  <p className={`${TRANSACTION_TYPE[tran.type].color} font-bold`}>
                    {TRANSACTION_TYPE[tran.type].name}
                  </p>
                  <p className="text-white-30 font-medium text-[10px]">{tran.description}</p>
                </td>
                <td className="px-4 py-2 bg-gray-purple h-[84px]">
                  <button type="button" className={WALLET_STYLE_STATUS[tran.status]}>
                    <span className="capitalize text-xs">
                      {tran.status}
                    </span>
                  </button>
                </td>
                <td className="px-4 py-2 bg-gray-purple h-[84px]">
                  <span className="text-sm text-dark-gray dark:text-white font-semibold whitespace-nowrap">
                    {tran.amount > 0 ? (
                      <span className="text-[#4caf50]">+{tran.amount}</span>
                    ) : (
                      <span className="text-light-red"> {tran.amount}</span>
                    )}
                  </span>
                </td>

                <td className="px-4 py-2 bg-gray-purple h-[84px] rounded-r-[14px]">
                  <span className="text-sm text-white whitespace-nowrap font-medium">
                    {formatDate(tran.createdAt)}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="flex justify-end pb-4 mt-2">
          <button
            disabled={!transaction.hasPrevPage}
            onClick={prevPage}
            className="inline-flex items-center px-4 py-2 mr-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5 mr-2"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
            Previous
          </button>
          <button
            disabled={!transaction.hasNextPage}
            onClick={nextPage}
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          >
            Next
            <svg
              aria-hidden="true"
              className="w-5 h-5 ml-2"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
}
