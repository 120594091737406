import React, { useEffect, useRef } from 'react';
import { IconLevel } from 'components/Helpers/Icons';

export const LEVEL_INT = {
    MEMBER: 0,
    BEGINNER: 1,
    SILVER: 2,
    GOLD: 3,
    RUBY: 4,
    DINAMOND: 5,
    CROWN: 6,
    FOUNDER_RUBY: 7,
    FOUNDER_DIAMOND: 8,
    FOUNDER_CROWN: 9,
};

const LEVEL_NAMES = {
    [LEVEL_INT.MEMBER]: 'Member',
    [LEVEL_INT.BEGINNER]: 'Beginner',
    [LEVEL_INT.SILVER]: 'Silver',
    [LEVEL_INT.GOLD]: 'Gold',
    [LEVEL_INT.RUBY]: 'Ruby',
    [LEVEL_INT.DINAMOND]: 'Diamond',
    [LEVEL_INT.CROWN]: 'Crown',
    [LEVEL_INT.FOUNDER_RUBY]: 'Founder Ruby',
    [LEVEL_INT.FOUNDER_DIAMOND]: 'Founder Diamond',
    [LEVEL_INT.FOUNDER_CROWN]: 'Founder Crown',
};

const Capitalize = (str) => str.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

const LevelUser = ({ level, currentLevel }) => {
    const isActive = currentLevel === level ? 'bg-gray-blue-60 border-white-12 text-white border rounded-full' : 'text-gray-60';
    const activeLevelRef = useRef(null);

    useEffect(() => {
        if (currentLevel === level) {
            setTimeout(() => {
                const parentElement = activeLevelRef.current?.parentElement;
                const offsetTop = activeLevelRef.current?.offsetTop - parentElement?.offsetTop;

                parentElement?.scrollTo({
                    top: offsetTop,
                    behavior: 'smooth'
                });
            }, 100);
        }
    }, [currentLevel, level]);

    return (
        <div className='flex items-center gap-2 mb-2.5' ref={activeLevelRef}>
            <div className="w-[40px] h-[40px] flex items-center justify-center">
                <IconLevel name={level ? level.toLowerCase().replace(' ', '_') : 'member'} width={30} height={30}/>
            </div>
            <div className={`text-sm font-semibold capitalize py-2 px-2.5 ${isActive}`}>
                {Capitalize(level)}
            </div>
        </div>
    );
};

export default LevelUser;