/* eslint-disable react/no-children-prop */
import Icons, { IconCommon } from "components/Helpers/Icons";
import Modal from "components/Helpers/Modal";
import { useState } from "react";
import TreeMenu from "react-simple-tree-menu";
import { toast } from "react-toastify";
import { copyText, formatDate } from "utils/common";
import SectionTitle from "../Helpers/SectionTitle";

export default function TreeSystem({ members }) {

  const [openModal, setOpenModal] = useState(false);
  const [seletedItem, setSeletedItem] = useState(null);
  const domain = window.location.origin;

  const handleCopy = (value) => {
    copyText(value);
    toast.success("Copied");
  };

  const renderTitle = seletedItem && (
    <span className="m-auto flex items-center">
      <Icons name="people-hover" />
      <span className="ml-2"> {seletedItem.full_name}</span>
    </span>
  );
  const renderItem = seletedItem && (
    <div className="w-80 sm:w-96">
      <div className="py-4 flex items-center">
        <IconCommon name="id.svg" height={22} />
        <span className="mx-2 font-bold">ID: </span>
        <span>{seletedItem.refer_code}</span>
      </div>
      <hr />
      <div className="py-4 flex items-center">
        <IconCommon name="date.svg" height={16} />
        <span className="mx-2 font-bold">Created At: </span>
        <span>{formatDate(seletedItem.createdAt)}</span>
      </div>
      <hr />
      <div className="flex py-4 items-center">
        <IconCommon name="member1.svg" />
        <span className="mx-2 font-bold"> Referral link: </span>
        <span
          className="flex items-center bg-[#046ce6] rounded-[5px] p-1 cursor-pointer text-white"
          onClick={(_) =>
            handleCopy(`${domain}/signup?referred_by=${seletedItem?.refer_code}`)
          }
        >
          <IconCommon name="copy.svg" width={20} height={20} />
          Copy Link
        </span>
      </div>
      <hr />
    </div>
  );

  return (
    <div className="pb-10">
      <SectionTitle name="Tree system" className="mb-4" />
      <div className="py-3 mb-5 overflow-x-auto">
        <div className="tree-member text-dark-gray dark:text-white min-w-[500px]">
          {members && (
            // <FolderTree data={members} showCheckbox={false} readOnly />
            <TreeMenu
              data={members}
              hasSearch={false}
              onClickItem={(item) => {
                setSeletedItem(item);
                setOpenModal(true);
              }}
            />
          )}
        </div>
        <Modal
          setShowModal={setOpenModal}
          showModal={openModal}
          children={renderItem}
          title={renderTitle}
        />
      </div>
    </div>
  );
}
