/* eslint-disable prefer-const */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-restricted-syntax */
import InputCom from "components/Helpers/Inputs/InputCom";
import React from "react";

export default function Otp({ otp, setOtp }) {
  // useEffect(() => {
  //   const otp = document.querySelector("#otp-inputs");

  //   for (const pin of otp.children) {
  //     pin.onkeyup = () => {
  //       if (pin.nextSibling) {
  //         pin.nextSibling.children.otp.focus();
  //       }
  //     };
  //   }
  // });

  return (
    <>
      <div className="otp-input-com  mb-8" id="otp-inputs">
        <InputCom
          placeholder="42****"
          name="referred_by"
          type="text"
          iconName="password"
          value={otp}
          inputHandler={(e) => setOtp(e.target.value)}
          maxLength={6}
        />
        {/* <div className="input-wrapper border border-light-purple dark:border-[#5356fb29]  sm:w-14 sm:h-14 w-12 h-12 rounded-[50px]  overflow-hidden relative ">
          <input
            className="input-field placeholder:text-base leading-14 text-center items-center text-2xl font-bold text-dark-gray dark:text-white w-full h-full bg-[#FAFAFA] dark:bg-[#11131F]  focus:ring-2 focus:outline-none"
            type="text"
            maxLength={1}
            id="otp"
          />
        </div>
        <div className="input-wrapper border border-light-purple dark:border-[#5356fb29]  sm:w-14 sm:h-14 w-12 h-12 rounded-[50px] overflow-hidden relative ">
          <input
            className="input-field placeholder:text-base text-2xl font-bold leading-14 text-center  text-dark-gray dark:text-white w-full h-full  bg-[#FAFAFA] dark:bg-[#11131F]  focus:ring-2 focus:outline-none"
            type="text"
            maxLength={1}
            id="otp"
          />
        </div>
        <div className="input-wrapper border border-light-purple dark:border-[#5356fb29]  sm:w-14 sm:h-14 w-12 h-12 rounded-[50px] overflow-hidden relative ">
          <input
            className="input-field placeholder:text-base text-2xl font-bold  leading-14 text-center  text-dark-gray dark:text-white w-full h-full  bg-[#FAFAFA] dark:bg-[#11131F]  focus:ring-2 focus:outline-none"
            type="text"
            maxLength={1}
            id="otp"
          />
        </div>
        <div className="input-wrapper border border-light-purple dark:border-[#5356fb29]  sm:w-14 sm:h-14 w-12 h-12 rounded-[50px]  overflow-hidden relative ">
          <input
            className="input-field placeholder:text-base text-2xl font-bold leading-14 text-center  text-dark-gray dark:text-white w-full h-full  bg-[#FAFAFA] dark:bg-[#11131F]  focus:ring-2 focus:outline-none"
            type="text"
            maxLength={1}
            id="otp"
          />
        </div>
        <div className="input-wrapper border border-light-purple dark:border-[#5356fb29]  sm:w-14 sm:h-14 w-12 h-12 rounded-[50px]  overflow-hidden relative ">
          <input
            className="input-field placeholder:text-base text-2xl font-bold leading-14 text-center  text-dark-gray dark:text-white w-full h-full  bg-[#FAFAFA] dark:bg-[#11131F]  focus:ring-2 focus:outline-none"
            type="text"
            maxLength={1}
            id="otp"
          />
        </div>
        <div className="input-wrapper border border-light-purple dark:border-[#5356fb29]  sm:w-14 sm:h-14 w-12 h-12 rounded-[50px] overflow-hidden relative ">
          <input
            className="input-field placeholder:text-base text-2xl font-bold  leading-14 text-center text-dark-gray dark:text-white w-full h-full  bg-[#FAFAFA] dark:bg-[#11131F]  focus:ring-2 focus:outline-none"
            type="text"
            maxLength={1}
            id="otp"
          />
        </div> */}
      </div>
    </>
  );
}
