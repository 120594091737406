/* eslint-disable react-hooks/exhaustive-deps */
import { ROUTERS } from "config/routes";
import { GlobalContext } from "contexts/GlobalContext";
import { useContext, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import UserService from "services/users";

const AuthRoute = ({ redirectPath = "/login", children }) => {
  const token = localStorage.getItem("auth");
  const { getUserAuth, getUserGroup } = useContext(GlobalContext);

  useEffect(() => {
    if (token) {
      getUserAuth();
      getUserGroup();
    }
  }, [token]);

  if (!token) {
    return <Navigate to={redirectPath} replace />;
  }

  return children || <Outlet />;
};

export default AuthRoute;
