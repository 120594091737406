/* eslint-disable react/button-has-type */

import Button from "components/Helpers/Button";
import { GlobalContext } from "contexts/GlobalContext";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import InvestService from "services/invest";
import { formatDate, formatNumber } from "utils/common";
import { INVEST_STATUS, INVEST_STYLE_STATUS } from "utils/constant";
import ModalConfirm from "./ModalConfirm";

export default function ModalInvestDetail({ showModal, setShowModal, seletedItem, callback }) {
  const { system } = useContext(GlobalContext);
  const [remainDays, setRemainDays] = useState();
  const [loading, setLoading] = useState({ reinvest: false, cancel: false });

  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [confirmModalType, setConfirmModalType] = useState(null);

  const handleOpenConfirmModal = (type = "reinvest" || "cancel") => {
    setConfirmModalType(type);
    setOpenModalConfirm(!openModalConfirm);
  }

  useEffect(() => {
    const cal = seletedItem?.investment.time_lock - seletedItem?.investment.number_unlock
    setRemainDays(cal);
  }, [seletedItem])

  const handleReinvestment = async () => {
    setOpenModalConfirm(!openModalConfirm);
    setLoading({ ...loading, reinvest: true })
    
    const res = await InvestService.confirmWaitingPackage({
      body: {
        id: seletedItem._id,
        status: INVEST_STATUS.REINVESTED
      },
    });

    setTimeout(() => {
      setShowModal(false);
      setLoading({ ...loading, reInvest: false })
      toast.success("Reinvest package successfully!");
    }, 2000);
    callback()
  }

  const handleCancelInvestment = async () => {
    setOpenModalConfirm(!openModalConfirm);
    setLoading({ ...loading, cancel: true })

    const res = await InvestService.confirmWaitingPackage({
      body: {
        id: seletedItem._id,
        status: INVEST_STATUS.WAITING_ADMIN_CONFIRM
      },
    });

    setTimeout(() => {
      setShowModal(false);
      setLoading({ ...loading, cancel: false })
      toast.success("Sent request cancel package successfully! Please waiting admin confirm.");
    }, 2000);
    callback()
  }

  return (
    <>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="w-96 sm:w-3/4 border-0 bg-white dark:bg-dark-white text-white text-dark-gray dark:text-white rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                <div className="flex items-center gap-4">
                  <h3 className="text-xl sm:text-3xl font-semibold">Detail Investment</h3>
                  <button type="button" className={INVEST_STYLE_STATUS[seletedItem.status][0] + " w-max"}>
                    {INVEST_STYLE_STATUS[seletedItem.status][1]}
                  </button>
                </div>

                <button
                  type="button"
                  className="ml-5"
                  onClick={() => setShowModal(false)}
                >
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M32 14.3645C32 15.5022 32 16.6399 32 17.7779C31.8143 17.8408 31.8787 18.0048 31.8565 18.1334C30.6952 24.8402 26.8853 29.2664 20.4091 31.362C19.4672 31.6668 18.4669 31.7917 17.4935 31.9997C16.3558 31.9997 15.2181 31.9997 14.0801 31.9997C13.8574 31.741 13.5279 31.7984 13.2475 31.7416C6.90872 30.4552 2.74424 26.7311 0.684152 20.6107C0.386668 19.7268 0.396442 18.7733 0 17.9199C0 16.6399 0 15.3598 0 14.0798C0.259 13.884 0.190585 13.5694 0.240675 13.3162C1.52285 6.84244 5.35655 2.66392 11.5936 0.623067C12.4549 0.34116 13.3785 0.343909 14.2221 0C15.3125 0 16.4029 0 17.4932 0C17.525 0.110258 17.6111 0.120948 17.7089 0.130417C24.2666 0.77242 29.8064 5.52819 31.449 11.9351C31.6552 12.739 31.8174 13.5542 32 14.3645ZM29.3431 16.0699C29.3718 8.68538 23.4154 2.66942 16.0684 2.66178C8.69759 2.65445 2.66972 8.58489 2.65353 15.8601C2.63704 23.2563 8.52319 29.2979 15.7868 29.3404C23.2862 29.3846 29.3144 23.4832 29.3431 16.0699Z"
                      fill="#fff"
                      fillOpacity="0.8"
                    />
                    <path
                      d="M14.1604 16.0221C12.3843 14.2567 10.6724 12.5534 8.95837 10.8525C8.53353 10.431 8.23421 9.97162 8.46175 9.34031C8.83956 8.29209 9.95101 8.07371 10.794 8.906C12.3611 10.4536 13.9344 11.9963 15.4529 13.5909C15.9202 14.0817 16.1447 14.0005 16.5662 13.5643C18.0961 11.9804 19.6617 10.4307 21.2282 8.88248C22.0596 8.06058 23.208 8.30064 23.5522 9.35008C23.7584 9.97865 23.459 10.4383 23.0336 10.8619C21.489 12.3991 19.9531 13.9443 18.4088 15.4815C18.2421 15.6476 18.0408 15.779 17.8188 15.9558C19.629 17.7563 21.366 19.4676 23.0831 21.1987C23.934 22.0567 23.6875 23.2106 22.6072 23.556C21.9658 23.761 21.5223 23.4186 21.1067 23.0023C19.5502 21.444 17.9757 19.9031 16.448 18.3171C16.0616 17.9157 15.8854 17.9811 15.5375 18.3378C13.9835 19.9318 12.399 21.4956 10.8242 23.0692C10.4015 23.4916 9.94887 23.7768 9.30961 23.516C8.27819 23.0948 8.06073 22.0814 8.87591 21.2418C10.0307 20.0528 11.2118 18.8891 12.3895 17.7221C12.9588 17.1577 13.5462 16.6106 14.1604 16.0221Z"
                      fill="#fff"
                      fillOpacity="0.8"
                    />
                  </svg>
                </button>
              </div>
              <div className="content overflow-style-none overflow-y-scroll pt-6 pb-[1rem] md:px-[30px] px-[23px] w-full">
                <div className="mb-4">
                  <p className="text-base sm:text-xl tracking-wide text-dark-gray dark:text-white">
                    Investment code: #{seletedItem._id}
                  </p>
                </div>
                <div className="mb-4">
                  <p className="text-base sm:text-xl tracking-wide text-dark-gray dark:text-white">
                    Amount invest: {formatNumber(seletedItem.investment.amount)}{" "}
                    <span className="text-[#ff9800]">{system.token}</span>
                  </p>
                </div>
                <div className="mb-4">
                  <p className="text-base sm:text-xl tracking-wide text-dark-gray dark:text-white">
                    Period: {seletedItem.investment.time_lock} days
                  </p>
                </div>
                <div className="mb-4">
                  <p className="text-base sm:text-xl tracking-wide text-dark-gray dark:text-white">
                    Remain days:{" "}
                    {remainDays}{" "}
                    Days
                  </p>
                </div>

                <div className="mb-4">
                  <p className="text-base sm:text-xl tracking-wide text-dark-gray dark:text-white ">
                    Daily profit: {seletedItem.investment.daily_profit || 0}{" "}
                    <span className="text-[#ff9800]">{system.token}</span>
                  </p>
                </div>

                <div className="mb-4">
                  <p className="text-base sm:text-xl tracking-wide text-dark-gray dark:text-white">
                    Total profit: {formatNumber(seletedItem.investment.total_profit || 0)}{" "}
                    <span className="text-[#ff9800]">{system.token}</span>
                  </p>
                </div>

                <div className="mb-4">
                  <p className="text-base sm:text-xl tracking-wide text-dark-gray dark:text-white">
                    Created At: {formatDate(seletedItem.createdAt)}
                  </p>
                </div>

                {remainDays <= 10 && seletedItem?.status && [INVEST_STATUS.WAITING_USER_CONFIRM, INVEST_STATUS.SUCCESS].includes(seletedItem?.status) && (
                  <div className="flex space-x-2.5 border-t border-solid border-slate-200 py-5 justify-end">
                    <Button
                      onClick={() => handleOpenConfirmModal("reinvest")}
                      disabled={remainDays > 0 || loading.reinvest || !(seletedItem.status === INVEST_STATUS.WAITING_USER_CONFIRM)}
                      loading={loading.reinvest}
                      type="button"
                      className={`w-fit flex justify-center text-white primary-gradient sm:text-18 text-14 tracking-wide px-4 py-3 rounded-full ${remainDays > 0 ? 'btn-disabled' : ''}`}
                    >
                      Reinvestment
                    </Button>
                    <Button
                      onClick={() => handleOpenConfirmModal("cancel")}
                      disabled={remainDays > 0 || loading.cancel || !(seletedItem.status === INVEST_STATUS.WAITING_USER_CONFIRM)}
                      type="button"
                      loading={loading.cancel}
                      className={`w-fit flex justify-center background-danger sm:text-18 text-14 tracking-wide px-4 py-3 rounded-full ${remainDays > 0 ? 'btn-disabled' : ''}`}
                    >
                      <span className="text-white">Cancel and withdraw</span>
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}

      {/* Modal confirm */}
      <ModalConfirm
        type={confirmModalType}
        showModal={openModalConfirm}
        setShowModal={setOpenModalConfirm}
        handleConfirm={confirmModalType === 'cancel' ? handleCancelInvestment : handleReinvestment}
      />
    </>
  );
}
