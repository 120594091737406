import titleShape from "assets/images/shape/title-shape-two-white.svg";
import Button from "components/Helpers/Button";
import { ROUTERS } from "config/routes";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AuthService from "services/auth";
import CustomInputCom from "../../Helpers/Inputs/CustomInputCom";
import AuthLayout from "../AuthLayout";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const sendCode = async () => {
    try {
      setLoading(true);
      const res = await AuthService.postForgotPassword({
        body: {
          email,
        },
      });
      if (res && res.token) {
        navigate(
          `${ROUTERS.VERIFY_YOU}?type=update-password&token=${res.token}`
        );
      } else toast.error("Email not correct");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Email not correct");
    }
  };

  return (
    <>
      <AuthLayout slogan="Welcome to  Tosi Bank">
        <div className="content-wrapper xl:bg-white dark:bg-dark-white-gray 2xl:px-14 xl:px-15 px-5 sm:w-auto h-[518px] rounded-[28px] !bg-opacity-70">
          <div className="flex flex-col justify-center w-full h-full px-5">
            <div className="title-area flex flex-col justify-center items-center relative text-center mb-10">
              <h1 className="text-4xl sm:text-5xl font-bold sm:leading-[74px] text-dark-gray dark:text-white">
                Forgot Password
              </h1>
              <div className="shape sm:w-[377px] w-[270px] -mt-1 ml-5">
                <img src={titleShape} alt="shape" />
              </div>
            </div>
            <div className="input-area">
              <div className="input-item mb-10">
                <CustomInputCom
                  placeholder="example@gmail.com"
                  label="Email Address"
                  name="email"
                  type="email"
                  iconName="message"
                  value={email}
                  inputHandler={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="signin-area mb-3.5">
                <Button
                  onClick={(_) => sendCode()}
                  loading={loading}
                  className="w-full rounded-[50px] mb-5 h-[58px] text-xl text-dark font-bold flex justify-center bg-white items-center"
                >
                  Send Code
                </Button>
              </div>
            </div>
          </div>
        </div>
      </AuthLayout>
    </>
  );
}
