export const formatNumber = (number, minimumFractionDigits = 2) =>
  new Intl.NumberFormat([], {
    minimumFractionDigits,
  }).format(number);

export const formatCurrency = (number) =>
  new Intl.NumberFormat([], {
    minimumFractionDigits: 0,
  }).format(number);

export const formatDate = (date) => new Date(date).toLocaleString();

export function copyText(text) {
  return navigator.clipboard.writeText(text);
}

export const getTextByNumber = (text = "", number = 12) => {
  if (text.length < number) {
    return text;
  }
  return `${text.slice(0, number)}...`;
};

export const getAddress = (address = "") =>
  `${address.slice(0, 7)}......${address.slice(
    address.length - 7,
    address.length
  )}`;

export const zeroPad = (value, size) => {
  const s = `000000000${value}`;
  return s.substr(s.length - size);
};

export const getAge = (birthDateString) => {
  const today = new Date();
  const birthDate = new Date(birthDateString);

  const yearsDifference = today.getFullYear() - birthDate.getFullYear();

  const isBeforeBirthday =
    today.getMonth() < birthDate.getMonth() ||
    (today.getMonth() === birthDate.getMonth() &&
      today.getDate() < birthDate.getDate());

  return isBeforeBirthday ? yearsDifference - 1 : yearsDifference;
};

export const formatInputDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const formatDateTagP = (date) => {
  const new_date = new Date(date);
  const year = new_date.getFullYear();
  const month = String(new_date.getMonth() + 1).padStart(2, "0");
  const day = String(new_date.getDate()).padStart(2, "0");
  return `${month}/${day}/${year}`;
};
