/* eslint-disable react-hooks/exhaustive-deps */
import DarkModeContext from "components/Contexts/DarkModeContext";
import { GlobalContext } from "contexts/GlobalContext";
import { useContext, useMemo } from "react";
import ReactLoading from "react-loading";
import { LEVELS } from "utils/constant";
import SectionTitle from "../Helpers/SectionTitle";

export default function Leveling({ members, levelProgress }) {
  const { parameter, usersGroup, auth } = useContext(GlobalContext);
  const { colors } = useContext(DarkModeContext);

  console.log("members: ", members)

  const amount = useMemo(() => {
    if (usersGroup) {
      return usersGroup.filter(
        (u) =>
          u.count.level === parameter.level && u._id !== parameter.object_id && u.referred_by === auth.refer_code
      ).length;
    }
    return 0;
  }, [parameter.level, usersGroup]);

  return (
    <div className="mb-20">
      <SectionTitle name="Leveling up conditions" className="sm:mb-4" />
      <div
        className={`update-table w-full p-8 overflow-y-auto no-scrollbar rounded-2xl max-h-[660px]`}
      >
        <div className="relative w-full overflow-x-auto sm:rounded-lg">
          <table className="w-full text-white overflow-hidden text-dark-gray dark:text-white">
            <thead className="rounded-md">
              <tr
                className={`text-sm text-thin-light-gray font-bold whitespace-nowrap border-b dark:border-[#5356fb29] default-border-b`}
              >
                <th className="p-3">Level</th>
                <th className="p-3">Leveling up conditions</th>
                <th className="p-3">Monthly salaries</th>
                <th className="p-3">Bonus when leveling up</th>
                <th className="p-3">Branch level up reward</th>
              </tr>
            </thead>
            <tbody>
              {LEVELS.map((level) => (
                <tr
                  className={`text-center`}
                  style={{                    
                    borderBottom: parameter.level !== level.name ? `1px solid` : "",
                    borderColor: parameter.level !== level.name ?`#5356fb29` : "",
                  }}
                  key={level.id}
                >
                  <td className="p-0">
                    <div className={`p-3 text-center text-[15px] rounded-l-[28px] min-h-[120px] flex flex-col justify-center`} style={{
                      background:
                        parameter.level === level.name
                          ? `rgba(83, 86, 251, 0.16)`
                          : "",
                    }}>
                      {level.level}
                    </div>
                  </td>
                  <td className="p-0" style={{
                    background:
                      parameter.level === level.name
                        ? `rgba(83, 86, 251, 0.16)`
                        : "",
                  }}>
                    <div className={`p-3 text-center text-[14px]`} >
                      {level.condition}
                      {parameter.level === level.name && (
                        <div className="flex justify-center">
                          <ReactLoading
                            type={"spokes"}
                            color="#36d7b7"
                            width={20}
                            height={20}
                          />
                          <span className="ml-1 font-bold text-[#36d7b7]">
                            Progress {levelProgress}/3
                          </span>
                        </div>
                      )}
                    </div>
                  </td>
                  <td className="p-0 text-[#4caf50] text-[15px]" style={{
                    background:
                      parameter.level === level.name
                        ? `rgba(83, 86, 251, 0.16)`
                        : "",
                  }}>{level.salary}</td>
                  <td className="p-0 text-[#4caf50] text-[15px]" style={{
                    background:
                      parameter.level === level.name
                        ? `rgba(83, 86, 251, 0.16)`
                        : "",
                  }}>{level.bonus}</td>
                  <td className="p-0 ">
                    <div className={`p-3 text-center text-[15px] rounded-r-[28px] min-h-[120px] flex flex-col justify-center`} style={{
                      background:
                        parameter.level === level.name
                          ? `rgba(83, 86, 251, 0.16)`
                          : "",
                    }}>
                      {level.reward}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
