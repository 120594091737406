import { useNavigate } from "react-router-dom";
import ScannerComponent from "react-webcam-qr-scanner";

export default function Scanner({setData}) {
  const navigate = useNavigate()
  const handleDecode = (result) => {
    if (result) {
      navigate('/')
    }
    setData(result.data)
  }

  return (
    <ScannerComponent
      className="some-classname"
      onDecode={handleDecode}
      constraints={{ 
        audio: false, 
        video: { 
          facingMode: "environment" 
        } 
      }}
      captureSize={{ width: 1280, height: 720 }}
    />
  );
}