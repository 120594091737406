import queryString from "query-string";
import { request, parseErrorResponse } from "./request";

const getUserAuth = () =>
  new Promise((resolve, reject) => {
    request()
      .get("users/userAuth")
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getWallet = () =>
  new Promise((resolve, reject) => {
    request()
      .get("wallets")
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getMembers = () =>
  new Promise((resolve, reject) => {
    request()
      .get(`users/members`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const editUser = (req) =>
  new Promise((resolve, reject) => {
    request()
      .put(`users/edit`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const changePassword = (req) =>
  new Promise((resolve, reject) => {
    request()
      .put("users/changePassword", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const checkPassword = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post("users/checkpassword", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const check2FA = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post("users/check2fa", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getSecurity = () =>
  new Promise((resolve, reject) => {
    request()
      .get("users/security")
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const enable2FA = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post("users/enable-2fa", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const disable2FA = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post("users/disable-2fa", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getBalances = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`users/balances`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getRankings = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`users/rankings?${queryString.stringify(req.query)}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const createPinCode = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post("users/create-pin", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const resetPinCode = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post("users/reset-pin", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const checkPinExist = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get("users/check-exist-pin")
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const editUserSecure = (req) =>
  new Promise((resolve, reject) => {
    request()
      .put(`users/edit-secure`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const UserService = {
  getUserAuth,
  getSecurity,
  enable2FA,
  disable2FA,
  editUser,
  changePassword,
  getBalances,
  checkPassword,
  check2FA,
  getMembers,
  getRankings,
  createPinCode,
  resetPinCode,
  checkPinExist,
  editUserSecure,
  getWallet,
};

export default UserService;
