import { parseErrorResponse, request } from "./request";

const getSystemSetting = () =>
  new Promise((resolve, reject) => {
    request()
      .get("settings")
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const SystemSetting = {
  getSystemSetting,
};

export default SystemSetting;
