const Button = ({ loading, onClick, className, children, disabled }) => (
  <button
    type="button"
    disabled={disabled}
    className={`${className}  ${loading ? "active" : ""} ${disabled ? 'opacity-50' : 'opacity-100'}`}
    onClick={onClick}
  >
    {loading ? (
      <div className="signup btn-loader"></div>
    ) : (
      <span>{children}</span>
    )}
  </button>
);

export default Button;
