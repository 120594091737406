import { parseErrorResponse, request } from "./request";

const login = (body, params) =>
  new Promise((resolve, reject) => {
    request()
      .post("/login", body, {
        params,
      })
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const loginGoogle = (body, params) =>
  new Promise((resolve, reject) => {
    request()
      .post("/login-google", body, {
        params,
      })
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const register = (body, params) =>
  new Promise((resolve, reject) => {
    request()
      .post("/register", body, {
        params,
      })
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const verifyEmail = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post(`verify-email/`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const resendVerifyEmail = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post("resend-verify-email", req.params.token)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const postForgotPassword = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post("/forgot-password", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const resetPassword = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post("/reset-password", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const verifyLogin = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post("/verify-login", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const resendCode = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post("/resend-otp", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const checkUserExists = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post("/users/check-user-exists", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const confirmPhoneNumber = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post("/verify/phone/confirm", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const AuthService = {
  login,
  loginGoogle,
  register,
  resendVerifyEmail,
  postForgotPassword,
  resetPassword,
  verifyLogin,
  resendCode,
  checkUserExists,
  verifyEmail,
  confirmPhoneNumber,
};

export default AuthService;
