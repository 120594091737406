/* eslint-disable react/button-has-type */
/* eslint-disable dot-notation */
/* eslint-disable no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import TransactionService from "services/transaction";
import { STYLE_STATUS, TRANSACTION_TYPE, WALLET_STYLE_STATUS } from "utils/constant";
import {
  formatDate,
  formatNumber,
  getAddress,
} from "utils/common";

function RecentTransactionWidget(props, ref) {
  useImperativeHandle(ref, () => ({
    reload: () => {
      getTransactions(filterActive);
    },
  }));

  const transationFilterData = [
    {
      id: 1,
      name: "all",
      uniqueId: Math.random(),
    },
    {
      id: 2,
      name: "deposit",
      uniqueId: Math.random(),
    },
    {
      id: 3,
      name: "withdraw",
      uniqueId: Math.random(),
    },
  ];
  const [filterActive, setFilterActive] = useState(transationFilterData[0]);
  const [transaction, setTransaction] = useState({
    docs: [],
    hasNextPage: false,
    hasPrevPage: false,
  });
  const [lazyParams, setLazyParams] = useState({
    page: 1,
    limit: 10,
  });

  useEffect(() => {
    getTransactions(filterActive);
  }, [lazyParams]);

  const filterHander = (value) => {
    setFilterActive(value);
    getTransactions(value);
  };

  const getTransactions = async (filterActive) => {
    try {
      const res = await TransactionService.getTransactions({
        query: {
          ...lazyParams,
          type:
            filterActive.id === 1 ? ["withdraw", "deposit"] : filterActive.name,
        },
      });
      setTransaction(res);
    } catch (error) { }
  };

  const nextPage = () => {
    setLazyParams((p) => ({
      ...p,
      page: p.page + 1,
    }));
  };

  const prevPage = () => {
    setLazyParams((p) => ({
      ...p,
      page: p.page - 1,
    }));
  };

  return (
    <div className="recent-transaction-widget w-full h-full p-5 rounded-2xl bg-white dark:bg-blue-9  ">
      {/* heading */}
      <div className="heading sm:flex justify-center items-center">
        <ul className="flex sm:space-x-32 space-x-4 items-center justify-center">
          {transationFilterData.map((value) => (
            <li
              onClick={() => filterHander(value)}
              key={value.uniqueId}
              className={`md:text-2xl sm:text-xl text-base font-bold text-thin-light-gray hover:text-purple cursor-pointer border-b border-b-[3px] hover:border-purple uppercase dark:border-[#5356fb29] border-transparent pb-4 ${filterActive.id === value.id
                ? "!border-white text-white"
                : ""
                }`}
            >
              {value.name}
            </li>
          ))}
        </ul>
      </div>
      {/* content */}

      <div className="content overflow-auto h-[450px] no-scrollbar">
        <table className="table-auto w-full tracking-wide overflow-hidden border-spacing-y-2 border-separate">
          <thead>
            <tr className="text-base text-thin-light-gray whitespace-nowrap border-b dark:border-[#5356fb29] default-border-b ">
              <th className="p-3">#</th>
              <th className="p-3">Status</th>
              <th className="p-3">Value</th>
              <th className="p-3">Transaction</th>
              <th className="p-3">Time</th>
            </tr>
          </thead>
          <tbody>
            {transaction.docs.map((tran) => (
              <tr key={tran._id} className="text-center cursor-pointer">
                {/* Hash with link */}
                <td className="px-4 py-2 bg-gray-purple h-[60px] flex justify-center items-center rounded-l-[14px]">
                  <a
                    target="_blank"
                    href={`https://bscscan.com/tx/${tran.hash}`}
                    className="text-[#ff9800] text-sm font-medium"
                    rel="noreferrer"
                  >
                    {getAddress(tran.hash)}
                  </a>
                </td>

                {/* Status */}
                <td className="px-4 py-2 text-center bg-gray-purple h-[60px]">
                  <button type="button" className={WALLET_STYLE_STATUS[tran.status]}>
                    <span className="capitalize text-xs">
                      {tran.status}
                    </span>
                  </button>
                </td>

                {/* Value */}
                <td className="px-4 py-2 text-center bg-gray-purple h-[60px]">
                  <p
                    className={`eth text-sm font-semibold ${tran.amount > 0 ? "text-light-green" : "text-light-red"
                      }`}
                  >
                    {formatNumber(tran.amount)}
                  </p>
                </td>

                {/* Transaction Type */}
                <td className="px-4 py-2 text-center bg-gray-purple h-[60px]">
                  <p className="text-sm text-dark-gray dark:text-white font-medium">
                    {TRANSACTION_TYPE[tran.type].name}
                  </p>
                </td>

                {/* Time */}
                <td className="px-4 py-2 text-center text-white font-medium text-sm bg-gray-purple h-[60px] rounded-r-[14px]">
                  {formatDate(tran.createdAt)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-end pb-4 mt-2">
        <button
          disabled={!transaction.hasPrevPage}
          onClick={prevPage}
          className="inline-flex items-center px-4 py-2 mr-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
        >
          <svg
            aria-hidden="true"
            className="w-5 h-5 mr-2"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
          Previous
        </button>
        <button
          disabled={!transaction.hasNextPage}
          onClick={nextPage}
          className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
        >
          Next
          <svg
            aria-hidden="true"
            className="w-5 h-5 ml-2"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
      </div>
    </div>
  );
}
export default forwardRef(RecentTransactionWidget);
