export default function InvestItem({
  id,
  thumbnail,
  name,
  percent,
  onSelect,
  checked,
}) {
  return (
    <div
      onClick={() => onSelect(id)}
      className=" p-2  cursor-pointer"
    >
      <div className="mb-20 ">
        <span className="text-gray-60 uppercase font-bold text-sm relative after:content-[''] after:w-[1px] after:h-[120px] after:bg-[#292A37] after:block after:absolute after:left-[50%]">
          {name}
        </span>
      </div>
      <div className="relative">
        <input
          checked={checked}
          type="radio"
          name="donut"
          id={id}
          className=""
        />
        <div className="item-container dark:text-white flex flex-col px-5 py-8 border-[#292931] border rounded-[28px]">
          <div className="flex mb-6">
            <span className="radio-donut"></span>
            <span className="text-3xl ml-4">
              {percent}%
            </span>
          </div>
          <img src={thumbnail} alt="invest item" className="m-auto h-[100px]" />
        </div>
      </div>
    </div>
  );
}
