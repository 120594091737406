import queryString from "query-string";
import { parseErrorResponse, request } from "./request";

const getInvests = (req) =>
  new Promise((resolve, reject) => {
    const query = queryString.stringify(req.query);
    request()
      .get(`invests?${query}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const invest = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post("invests", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const confirmWaitingPackage = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post("invests/confirm-waiting-package", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const InvestService = {
  getInvests,
  invest,
  confirmWaitingPackage,
};
export default InvestService;
