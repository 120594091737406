/* eslint-disable no-unused-expressions */
/* global grecaptcha */
import DarkModeContext from "components/Contexts/DarkModeContext";
import Button from "components/Helpers/Button";
// import Scanner from "components/Scanner";
import ModalCom from "components/Helpers/ModalCom";
import { GlobalContext } from "contexts/GlobalContext";
import { useContext, useEffect, useMemo, useState } from "react";
import QrReader from "react-qr-scanner";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AuthService from "services/auth";
import SystemSetting from "services/settings";
import TransactionService from "services/transaction";
import { formatNumber } from "utils/common";
import { BALANCE_TYPE } from "utils/constant";
import firebase from "../../firebase";
import cryptoCoin from "../../assets/images/crypto-coin.png"

export default function Withdraw({ reload }) {
  const {
    balance,
    profitBalance: profitBalanceCtx,
    salaryAndCommissionBalance: salaryAndCommissionBalanceCtx,
    system,
    getUserAuth,
    auth,
  } = useContext(GlobalContext);
  const { colors } = useContext(DarkModeContext);
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState();
  const [balanceSelected, setBalanceSelected] = useState({
    balance_type: BALANCE_TYPE.BALANCES_V1,
    tsib: 0,
  });
  const [address, setAddress] = useState();
  const [verify, setVerify] = useState({
    phone: "",
    email: "",
  });
  const [isSend, setIsSend] = useState({
    phone: false,
    email: false,
  });
  const [expirationTime, setExpirationTime] = useState(0);
  const [fireBaseConfirmationResult, setFireBaseConfirmationResult] =
    useState("");

  const [showModalScanQR, setshowModalScanQR] = useState(false);
  const [systemSettings, setSystemSettings] = useState({
    isAllowWithdraw: false,
  });

  const navigate = useNavigate();

  const getSystemSettings = () => {
    SystemSetting.getSystemSetting().then((res) => {
      if (res) {
        const isAllowWithdraw = res.settings.find(
          (item) => item.name === "is_allow_withdraw"
        );
        if (isAllowWithdraw) {
          setSystemSettings({ isAllowWithdraw: isAllowWithdraw.value });
        }
      }
    });
  };

  useEffect(() => {
    getSystemSettings();
  }, []);

  // useEffect(() => {
  //   if(window.recaptchaVerifier){
  //     window.recaptchaVerifier.clear();
  //   }
  //   window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
  //     "recaptcha-container",
  //     { size: "invisible" }
  //   );
  //   window.recaptchaVerifier.render();
  // }, []);

  const balance_current = useMemo(() => {
    if (balance) {
      return balance.tsib || 0;
    }
    return 0;
  }, [balance]);

  const profitBalance = useMemo(() => {
    if (profitBalanceCtx) {
      return profitBalanceCtx.tsib || 0;
    }
    return 0;
  }, [profitBalanceCtx]);

  const salaryAndCommissionBalance = useMemo(() => {
    if (salaryAndCommissionBalanceCtx) {
      return +(salaryAndCommissionBalanceCtx.tsib || 0);
    }
    return 0;
  }, [salaryAndCommissionBalanceCtx]);

  useEffect(() => {
    setBalanceSelected({
      balance_type: BALANCE_TYPE.BALANCES_V1,
      tsib: balance_current,
    });
  }, [balance_current]);

  const handleChangeAmount = (value) => {
    if (balanceSelected.balance_type === BALANCE_TYPE.BALANCES_V1) {
      if (+value <= balanceSelected.tsib * 0.1) setAmount(value);
      else setAmount(balanceSelected.tsib * 0.1);
      return;
    }
    if (+value <= balanceSelected.tsib) setAmount(value);
    else setAmount(balanceSelected.tsib);
  };

  const handleSendCode = () => {
    if (!systemSettings.isAllowWithdraw) {
      toast.error("Do not allow withdraw at this time. Please try it later!");
      return;
    }
    setIsSend({
      ...isSend,
      email: true,
    });
    AuthService.resendCode({
      body: { email: auth.email },
    });
    toast.success("Send OTP to email successfully!");
  };

  const handleSendCodeSMS = () => {
    setIsSend({
      ...isSend,
      phone: true,
    });
    firebase
      .auth()
      .signInWithPhoneNumber(`${auth.phone}`, window.recaptchaVerifier)
      .then((confirmationResult) => {
        setFireBaseConfirmationResult(confirmationResult);
        setExpirationTime(60);
        window.confirmationResult = confirmationResult;
        toast.success("Send OTP to SMS successfully!");
      })
      .catch((err) => {
        console.log(err.code);
        window.recaptchaVerifier.render().then((widgetId) => {
          grecaptcha.reset(widgetId);
        });
        let msg = "Something wrong. Please try again later!";
        if (err.code === "auth/too-many-requests") {
          msg = "You are making too many requests. Please try again later!";
        }
        toast.error(msg);
      });
  };

  const handleWithdraw = async () => {
    try {
      setLoading(true);
      if (!amount || !address) {
        return toast.error("Please input data");
      }
      // if (clicked) {
      //   return;
      // }
      // setClicked(true);

      await TransactionService.withdraw({
        body: {
          amount,
          address,
          verifyEmail: verify.email,
          verifyPhone: verify.phone,
          targetWallet: balanceSelected.balance_type,
        },
      });
      setAmount("");
      setAddress("");
      setVerify({ phone: "", email: "" });
      setIsSend({ phone: false, email: false });
      toast.success("Success");
      reload && reload();
      getUserAuth();
      setLoading(false);
    } catch (error) {
      const msg =
        error.errors === "OTP_INCORRECT"
          ? "Email OTP incorrect!"
          : error.errors === "OTP_HAS_EXPIRED"
            ? "Email OTP has expried!"
            : "Email OTP invalid!";
      toast.error(error.errors);
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    if (!systemSettings.isAllowWithdraw) {
      toast.error("Do not allow withdraw at this time. Please try it later!");
      return;
    }
    setLoading(true);
    if (!amount || !address) {
      return toast.error("Please input data");
    }

    if (!fireBaseConfirmationResult) {
      toast.error(
        "The SMS code has expired or invalid. Please re-send the verification code SMS!"
      );
      setLoading(false);
      return;
    }

    fireBaseConfirmationResult
      .confirm(verify.phone)
      .then(async (result) => {
        await handleWithdraw();
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        toast.error(
          "The SMS code has expired or invalid. Please re-send the verification code SMS!"
        );
      });
  };

  const openScanQRModal = () => {
    setshowModalScanQR(!showModalScanQR);
  };

  const onSelectWallet = (e) => {
    if (e.target.value === BALANCE_TYPE.BALANCES_V1) {
      setBalanceSelected({
        balance_type: BALANCE_TYPE.BALANCES_V1,
        tsib: balance_current,
      });
    } else if (e.target.value === BALANCE_TYPE.PROFIT_BALANCES) {
      setBalanceSelected({
        balance_type: BALANCE_TYPE.PROFIT_BALANCES,
        tsib: profitBalance,
      });
    } else if (e.target.value === BALANCE_TYPE.SALARY_AND_COMMISSION_BALANCES) {
      setBalanceSelected({
        balance_type: BALANCE_TYPE.SALARY_AND_COMMISSION_BALANCES,
        tsib: salaryAndCommissionBalance,
      });
    }
  };

  return (
    <div className="investment-widget w-full h-full bg-blue-9 rounded-[28px] border border-t-[1px] border-white-12">
      <div className="px-[22px] py-5 bg-blue-dark rounded-[28px]">
        <div className="flex justify-between items-center mb-6 sm:flex-row flex-col">
          <h1 className="2xl:text-[50px] text-4xl font-bold text-white sm:mb-0 mb-3">Withdraw</h1>
          <div className="flex flex-col 2xl:gap-2.5 gap-0.5">
            <span className="text-white 2xl:text-base text-sm">Available balance</span>
            <p className="text-yellow-supernova font-medium">
              <span className="2xl:text-2xl text-xl mr-1">{formatNumber(balanceSelected.tsib)}</span>
              <span className="text-xs">TSIB </span>
            </p>
          </div>
        </div>
        <div className="flex flex-col">
          {auth?.status_kyc !== "approved" && (
            <Link to="/settings" className="text-red-500 text-sm">
              You haven't done the KYC yet. Please complete it first!
            </Link>
          )}
          
          {/* Select wallet */}
          {/* <SelectBox datas={[]} action={() => {}} /> */}
          <p className="mt-3 text-white text-sm">Select your wallet:</p>
          <div className="flex sm:gap-3 sm:flex-row flex-col">
            <select
              className={`select-wallet-tag w-full sm:w-1/2 px-6 py-[18px] mt-3 outline-none border border-white-12 rounded-full dark:bg-blue-9 dark:text-white font-normal text-sm`}
              onBlur={() => { }}
              onChange={onSelectWallet}
              value={balanceSelected.balance_type}
            >
              <option
                className="bg-black"
                value={BALANCE_TYPE.BALANCES_V1}
              >
                Old wallet
              </option>
              <option
                className="bg-black"
                value={BALANCE_TYPE.PROFIT_BALANCES}
              >
                Profit wallet
              </option>
              <option
                className="bg-black"
                value={BALANCE_TYPE.SALARY_AND_COMMISSION_BALANCES}
              >
                Salary and commission wallet
              </option>
            </select>
            {/* Amount */}
            <input
              value={amount}
              className={`w-full sm:w-1/2 px-6 py-[18px] mt-3 outline-none dark:text-white border border-white-12 rounded-full dark:bg-blue-9 font-semibold placeholder:text-white-30 placeholder:font-normal placeholder:text-sm`}
              placeholder="Amount to withdraw"
              type="number"
              onChange={(e) => handleChangeAmount(e.target.value)}
            />
          </div>
          {(balanceSelected.balance_type === BALANCE_TYPE.PROFIT_BALANCES || balanceSelected.balance_type === BALANCE_TYPE.SALARY_AND_COMMISSION_BALANCES) && (
            <div className="mt-1 text-white-30 text-sm">
              Minimum withdrawal 100 TSIB - (Fee 0.5%)
            </div>
          )}

          {balanceSelected.balance_type === BALANCE_TYPE.BALANCES_V1 && (
            <div className="mt-1 text-white-30 text-sm">
              You can withdraw maximum 10% from this wallet.
            </div>
          )}
          
          <div className="relative mt-3">
            <input
              value={address}
              className={`w-full px-6 py-[18px] pr-12 outline-none border border-white-12 rounded-full dark:bg-blue-9 dark:text-white placeholder:text-white-30 placeholder:font-normal placeholder:text-sm`}
              placeholder="Wallet address USDT (BEP20/BSC)"
              type="text"
              onChange={(e) => setAddress(e.target.value)}
            />
            <button
              type="button"
              className="absolute top-1/2 right-4 -translate-y-1/2"
              onClick={openScanQRModal}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  d="M11 8h-1v-2h1v1h2v1h-1v1h-1v-1zm2 12v-1h-1v1h1zm-1-15v-1h-2v1h1v1h1v-1zm8-1v6h-1v-1h-4v-5h5zm-1 4v-3h-3v3h3zm-14 2h-1v1h2v-1h-1zm0 3h1v1h1v-3h-1v1h-2v2h1v-1zm5 1v2h1v-2h-1zm4-10h-1v3h1v-3zm0 5v-1h-1v1h1zm3-2h1v-1h-1v1zm-10-1h-1v1h1v-1zm2-2v5h-5v-5h5zm-1 1h-3v3h3v-3zm9 5v1h-1v-1h-2v1h-1v-1h-3v-1h-1v1h-1v1h1v2h1v-1h1v2h1v-2h3v1h-2v1h2v1h1v-3h1v1h1v2h1v-1h1v-1h-1v-1h-1v-1h1v-1h-2zm-11 8h1v-1h-1v1zm-2-3h5v5h-5v-5zm1 4h3v-3h-3v3zm12-3v-1h-1v1h1zm0 1h-1v1h-1v-1h-1v-1h1v-1h-2v-1h-1v2h-1v1h-1v3h1v-1h1v-1h2v2h1v-1h1v1h2v-1h1v-1h-2v-1zm-9-3h1v-1h-1v1zm10 2v1h1v1h1v-3h-1v1h-1zm2 4v-1h-1v1h1zm0-8v-1h-1v1h1zm-2-10h4v4h2v-6h-6v2zm-16 4v-4h4v-2h-6v6h2zm4 16h-4v-4h-2v6h6v-2zm16-4v4h-4v2h6v-6h-2z"
                  className="fill-purple dark:fill-white"
                />
              </svg>
            </button>
          </div>

          <div>
            {/* Veryfy code from email */}
            <input
              value={verify.email}
              className={`w-full px-6 py-[18px] mt-3 outline-none border border-white-12 rounded-full dark:bg-blue-9 dark:text-white placeholder:text-white-30 placeholder:font-normal placeholder:text-sm`}
              placeholder="Verify Code from email"
              type="text"
              onChange={(e) => setVerify({ ...verify, email: e.target.value })}
            />

            {auth?.status_kyc === "approved" && (
              <div
                onClick={handleSendCode}
                className="text-base text-[#FCB711] mt-6 text-right font-bold cursor-pointer "
              >
                <span className="border-b py-1 border-[#FCB711]">
                  {!isSend.email ? "Send Code" : "Resend"}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="px-[22px] pt-3 pb-[30px]">
        <div className="flex justify-between items-end mb-3">
          <img src={cryptoCoin} alt="shield" className="w-[45px] h-[45px]" />
        </div>

        <div className="flex justify-between sm:flex-row flex-col">
          <div className="flex flex-col">
            <div className="text-white text-sm">
              Amount you will receive: (- Fee 0.5%)
            </div>
            <p className="text-yellow-supernova font-medium">
              <span className="text-2xl mr-1">{amount ? amount - amount * 0.005 : 0}</span>
              <span className="text-xs">TSIB</span>
            </p>
          </div>

          <Button
            loading={loading}
            disabled={
              !verify.email ||
              !amount ||
              (+amount < 100 && balanceSelected.balance_type !== BALANCE_TYPE.BALANCES_V1) ||
              auth?.status_kyc !== "approved" ||
              loading
            }
            className={`cursor-pointer rounded-full sm:mt-0 mt-4 py-4 px-12 text-blue-9 text-center font-semibold flex justify-center items-center  ${amount >= 100 ? "bg-pink" : "bg-white"
              } `}
            // onClick={() => handleSubmit()}
            onClick={() => handleWithdraw()}
          >
            Submit
          </Button>
        </div>
      </div>
      {/* <Scanner setData={setAddress}/> */}
      {showModalScanQR && (
        <ModalCom action={openScanQRModal} situation={showModalScanQR}>
          <div className="w-full h-full lg:overflow-hidden lg:rounded-2xl bg-white dark:bg-dark-white  ">
            <div className="heading flex justify-between items-center py-6  md:px-[30px] px-[23px] border-b dark:border-[#5356fb29]  border-light-purple dark:border-[#5356fb29] ">
              <p className="text-26 font-bold text-dark-gray dark:text-white tracking-wide">
                Scan QR
              </p>
              <button type="button" onClick={openScanQRModal}>
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M32 14.3645C32 15.5022 32 16.6399 32 17.7779C31.8143 17.8408 31.8787 18.0048 31.8565 18.1334C30.6952 24.8402 26.8853 29.2664 20.4091 31.362C19.4672 31.6668 18.4669 31.7917 17.4935 31.9997C16.3558 31.9997 15.2181 31.9997 14.0801 31.9997C13.8574 31.741 13.5279 31.7984 13.2475 31.7416C6.90872 30.4552 2.74424 26.7311 0.684152 20.6107C0.386668 19.7268 0.396442 18.7733 0 17.9199C0 16.6399 0 15.3598 0 14.0798C0.259 13.884 0.190585 13.5694 0.240675 13.3162C1.52285 6.84244 5.35655 2.66392 11.5936 0.623067C12.4549 0.34116 13.3785 0.343909 14.2221 0C15.3125 0 16.4029 0 17.4932 0C17.525 0.110258 17.6111 0.120948 17.7089 0.130417C24.2666 0.77242 29.8064 5.52819 31.449 11.9351C31.6552 12.739 31.8174 13.5542 32 14.3645ZM29.3431 16.0699C29.3718 8.68538 23.4154 2.66942 16.0684 2.66178C8.69759 2.65445 2.66972 8.58489 2.65353 15.8601C2.63704 23.2563 8.52319 29.2979 15.7868 29.3404C23.2862 29.3846 29.3144 23.4832 29.3431 16.0699Z"
                    fill="#374557"
                    fillOpacity="0.6"
                  />
                  <path
                    d="M14.1604 16.0221C12.3843 14.2567 10.6724 12.5534 8.95837 10.8525C8.53353 10.431 8.23421 9.97162 8.46175 9.34031C8.83956 8.29209 9.95101 8.07371 10.794 8.906C12.3611 10.4536 13.9344 11.9963 15.4529 13.5909C15.9202 14.0817 16.1447 14.0005 16.5662 13.5643C18.0961 11.9804 19.6617 10.4307 21.2282 8.88248C22.0596 8.06058 23.208 8.30064 23.5522 9.35008C23.7584 9.97865 23.459 10.4383 23.0336 10.8619C21.489 12.3991 19.9531 13.9443 18.4088 15.4815C18.2421 15.6476 18.0408 15.779 17.8188 15.9558C19.629 17.7563 21.366 19.4676 23.0831 21.1987C23.934 22.0567 23.6875 23.2106 22.6072 23.556C21.9658 23.761 21.5223 23.4186 21.1067 23.0023C19.5502 21.444 17.9757 19.9031 16.448 18.3171C16.0616 17.9157 15.8854 17.9811 15.5375 18.3378C13.9835 19.9318 12.399 21.4956 10.8242 23.0692C10.4015 23.4916 9.94887 23.7768 9.30961 23.516C8.27819 23.0948 8.06073 22.0814 8.87591 21.2418C10.0307 20.0528 11.2118 18.8891 12.3895 17.7221C12.9588 17.1577 13.5462 16.6106 14.1604 16.0221Z"
                    fill="#374557"
                    fillOpacity="0.6"
                  />
                </svg>
              </button>
            </div>
            <div className="content h-screen overflow-style-none overflow-y-scroll pt-6 pb-[16rem] md:px-[30px] px-[23px] w-full">
              <div className="content-items w-full">
                <QrReader
                  delay={100}
                  onError={(err) => {
                    console.error(err);
                  }}
                  onScan={(e) => {
                    if (e) {
                      setAddress(e.text);
                      openScanQRModal();
                    }
                  }}
                  key="environment"
                  constraints={{
                    audio: false,
                    video: { facingMode: "environment" },
                  }}
                />
              </div>
            </div>
          </div>
        </ModalCom>
      )}
    </div>
  );
}
