/* eslint-disable react/destructuring-assignment */
import { ROUTERS } from "config/routes";
import { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserService from "services/users";
import { INVEST_PACKAGE } from "utils/constant";

const defaultValue = {
  auth: null,
  balance: {
    tsib: 0,
  },
  profitBalance: {
    tsib: 0,
  },
  salaryAndCommissionBalance: {
    tsib: 0,
  },
  depositBalance: {
    tsib: 0,
  },
  system: {
    token: "TSIB",
    logo: "assets/images/logo.png",
    rate: 1,
  },
  parameter: {
    count: {},
    level: null,
    direct_commission: 0.03,
    object_id: null,
  },
  levelInvest: {},
  levelGroup: {},
  wallet: null,
  usersGroup: [],
  getWallet: (_) => {},
  setBalance: (_) => {},
  setAuth: (_) => {},
  getUserAuth: (_) => {},
  getUserGroup: (_) => {},
};

export const GlobalContext = createContext(defaultValue);

export const GlobalProvider = (props) => {
  const navigate = useNavigate();
  const [auth, setAuth] = useState(null);
  const [balance, setBalance] = useState(defaultValue.balance);
  const [profitBalance, setProfitBalance] = useState(defaultValue.profitBalance);
  const [salaryAndCommissionBalance, setSalaryAndCommissionBalance] = useState(defaultValue.salaryAndCommissionBalance);
  const [depositBalance, setDepositBalance] = useState(defaultValue.depositBalance);
  const [parameter, setParameter] = useState(defaultValue.parameter);
  const [levelInvest, setLevelInvest] = useState(defaultValue.levelInvest);
  const [levelGroup, setLevelGroup] = useState(defaultValue.levelGroup);
  const [wallet, setWallet] = useState(defaultValue.wallet);
  const [usersGroup, setUsersGroup] = useState([]);

  const getUserAuth = () => {
    UserService.getUserAuth()
      .then((data) => {
        const { user } = data;
        if (user) {
          setAuth(user);
          setParameter(user.count);
          setBalance(user.finance.balances);
          setProfitBalance(user.finance.profit_balances);
          setSalaryAndCommissionBalance(user.finance.salary_and_commission_balances);
          setDepositBalance(user.finance.deposit_balances);
          const _levelInvest = INVEST_PACKAGE.find(
            (i) => i.percent === user.count.direct_commission * 100
          );
          setLevelInvest(
            _levelInvest || {
              id: "0",
              name: "PLAYER",
              percent: 0,
              icon: "assets/images/player.png",
              value: 0,
            }
          );
        }
      })
      .catch(() => {
        localStorage.removeItem("auth");
        navigate(ROUTERS.LOGIN);
      });
  };

  const getUserGroup = async () => {
    const res = await UserService.getMembers();
    if (res && res.length > 0) {
      setUsersGroup(res);
    }
  };

  const getWallet = async () => {
    try {
      const res = await UserService.getWallet();
      setWallet(res);
    } catch (error) {}
  };

  return (
    <GlobalContext.Provider
      value={{
        ...defaultValue,
        auth,
        balance,
        depositBalance,
        profitBalance,
        salaryAndCommissionBalance,
        parameter,
        levelInvest,
        wallet,
        usersGroup,
        setBalance,
        setProfitBalance,
        setSalaryAndCommissionBalance,
        setDepositBalance,
        setAuth,
        getUserAuth,
        getWallet,
        getUserGroup,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};
