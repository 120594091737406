import { twMerge } from "tailwind-merge";

export default function SectionTitle({ name, className }) {
  const classes = twMerge(`flex items-center gap-12 ${className ?? ""}`);
  return (
    <div className={classes}>
      <p className="py-3 font-bold 2xl:text-5xl sm:text-4xl text-2xl text-white">
        {name}
      </p>
      <div className="lg:block hidden flex-grow min-w-[350px] max-w-[600px]">
        <div className="bg-white-12 h-[1px]">
        </div>
      </div>
    </div>
  );
}
