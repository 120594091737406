/* eslint-disable no-use-before-define */
import { INVEST_PACKAGE, INVEST_PERIOD } from "utils/constant";

import { GlobalContext } from "contexts/GlobalContext";
import { useContext, useEffect, useState } from "react";
import InvestService from "services/invest";
import { formatNumber } from "utils/common";
import Layout from "../Partials/Layout";
import History from "./History";
import InvestCategory from "./InvestCategory";
import Invests from "./Invests";
import Packages from "./Packages";
import cryptoCoin from "../../assets/images/crypto-coin.png";
import separateImage from "../../assets/images/col-line-sep.svg"

export default function Invest() {
  const { parameter, levelInvest, auth } = useContext(GlobalContext);
  const { count } = parameter;
  const [histories, setHistories] = useState([]);

  const investCategories = [
    {
      id: "1",
      name: "Total investment",
      isShowLogo: true,
      value: formatNumber(count.invest || 0, 2),
      icon: levelInvest?.icon,
    },
    {
      id: "2",
      name: "Daily profit",
      value: formatNumber(count.daily_profit || 0, 2),
      icon: "assets/images/profit_day.svg",
    },
    {
      id: "3",
      name: "Total payback",
      value: formatNumber(count.total_payback || 0, 2),
      icon: "assets/images/total_payback.svg",
    },
  ];

  useEffect(() => {
    if (auth) {
      getInvestHistories();
    }
  }, [auth]);

  const getInvestHistories = async () => {
    const res = await InvestService.getInvests({
      query: {
        limit: 100,
        page: 1,
      },
    });
    if (res && res.docs) setHistories(res.docs);
  };

  return (
    <>
      <Layout>
        <Invests
          periods={INVEST_PERIOD}
          getInvestHistories={getInvestHistories}
        />
        <Packages data={INVEST_PACKAGE} />

        <History data={histories} getInvestHistories={getInvestHistories} />
      </Layout>
    </>
  );
}
