import React from "react";
import Icons from "../../Icons";

export default function CustomInput2Com({
  label,
  type,
  name,
  placeholder,
  iconName,
  inputHandler,
  value,
  disabled,
  maxLength,
}) {
  return (
    <div className="input-com">
      {label && (
        <label
          className="input-label text-dark-gray dark:text-white text-xl font-bold block mb-5"
          htmlFor={name}
        >
          {label}
        </label>
      )}
      <div className="input-wrapper border border-light-purple dark:border-white-12 w-full rounded-[50px] h-[58px] overflow-hidden relative ">
        <input
          placeholder={placeholder}
          value={value}
          onChange={inputHandler}
          className="input-field placeholder:text-base text-bese px-6 text-dark-gray dark:text-white w-full h-full bg-blue-9 dark:bg-blue-9  focus:ring-0 focus:outline-none"
          type={type}
          id={name}
          disabled={disabled}
          maxLength={maxLength}
        />
        {iconName && (
          <div className="absolute right-6 bottom-[19px] z-10">
            <Icons name={iconName} />
          </div>
        )}
      </div>
    </div>
  );
}
