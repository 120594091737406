import { Navigate, Outlet } from "react-router-dom";

const NoAuthRoute = ({ redirectPath = "/", children }) => {
  const isLogin = localStorage.getItem("auth");
  if (isLogin) {
    return <Navigate to={redirectPath} replace />;
  }

  return children || <Outlet />;
};

export default NoAuthRoute;
