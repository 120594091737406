import googleLogo from "assets/images/google-logo.svg";
import titleShapeWhite from "assets/images/shape/title-shape-white.svg";
import Button from "components/Helpers/Button";
import { ROUTERS } from "config/routes";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AuthService from "services/auth";
import CustomInputCom from "../../Helpers/Inputs/CustomInputCom";
import AuthLayout from "../AuthLayout";

export default function Login() {
  const navigate = useNavigate();

  const [checked, setValue] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  const rememberMe = () => {
    setValue(!checked);
  };
  const [email, setMail] = useState("");
  const [password, setPassword] = useState("");

  const handleEmail = (e) => {
    setMail(e.target.value);
  };
  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const doLogin = async () => {
    try {
      if (email !== "" && password !== "") {
        setLoginLoading(true);
        const res = await AuthService.login({
          email,
          password,
        });
        if (res && res.token) {
          localStorage.setItem("auth", `${res.token}`);
          toast.success("Login Successfully");
          navigate(ROUTERS.DASHBOARD, { replace: true });
          setLoginLoading(false);
        }
      }
    } catch (error) {
      setLoginLoading(false);
      if (error?.errors === "USER_NOT_VERIFY") {
        navigate(`${ROUTERS.VERIFY_YOU}?type=signup&email=${email}`);
      } else {
        toast.error("Invalid email or password");
      }
    }
  };

  return (
    <>
      <AuthLayout slogan="Welcome to Tosi Bank">
        <div className="content-wrapper flex justify-center items-center xl:bg-white dark:bg-dark-white-gray 2xl:w-[928px] xl:w-[500px] 2xl:h-[780px] xl:h-[700px] rounded-[28px] 2xl:px-16 xl:px-14 sm:px-7 px-5 lg:w-[650px] md:w-[550px] w-[500px] !bg-opacity-70 mx-3">
          <div className="w-full h-full pb-5">
            <div className="title-area flex flex-col justify-center items-center relative text-center mb-10">
              <h1 className="text-5xl font-bold leading-[74px] text-dark-gray dark:text-white mt-10">
                Log In
              </h1>
              <div className="shape -mt-5">
                <img src={titleShapeWhite} alt="shape" />
              </div>
            </div>
            <div className="input-area">
              <div className="input-item mb-5">
                <CustomInputCom
                  value={email}
                  inputHandler={handleEmail}
                  placeholder="example@gmail.com"
                  label="Email Address"
                  name="email"
                  type="email"
                  iconName="message"
                />
              </div>
              <div className="input-item mb-5">
                <CustomInputCom
                  value={password}
                  inputHandler={handlePassword}
                  placeholder="● ● ● ● ● ●"
                  label="Password"
                  name="password"
                  type="password"
                  iconName="password"
                />
              </div>
              <div className="forgot-password-area flex justify-between items-center mb-10">
                <div className="remember-checkbox flex items-center space-x-2.5">
                  <button
                    onClick={rememberMe}
                    type="button"
                    className="w-5 h-5 text-dark-gray dark:text-white flex justify-center items-center border border-light-gray rounded"
                  >
                    {checked && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    )}
                  </button>
                  <span
                    onClick={rememberMe}
                    className="text-base   text-dark-gray dark:text-white"
                  >
                    Remember Me
                  </span>
                </div>
                <a
                  onClick={(_) => navigate(ROUTERS.FORGOT_PASSWORD)}
                  className="text-base text-purple cursor-pointer"
                >
                  Forgot Password
                </a>
              </div>
              <div className="signin-area mb-5">
                <div className="flex justify-center">
                  <Button
                    className="btn-login rounded-[50px] mb-5 text-xl text-dark font-bold flex justify-center bg-purple items-center "
                    loading={loginLoading}
                    onClick={doLogin}
                  >
                    Sign In
                  </Button>
                </div>
                <a
                  href="#"
                  className="w-full border border-light-purple dark:border-blue-9  rounded-[50px] h-[58px] flex justify-center bg-[#FAFAFA] dark:bg-blue-9 items-center"
                >
                  <img className="mr-3" src={googleLogo} alt="google logo" />
                  <span className="text-lg text-thin-light-gray font-normal">
                    Sign In with Google
                  </span>
                </a>
              </div>
              <div className="signup-area flex justify-center">
                <p className="sm:text-lg text-sm text-thin-light-gray font-normal">
                  Dont’t have an account ?
                  <a
                    onClick={(_) => navigate(ROUTERS.REGISTER)}
                    className="ml-2 cursor-pointer text-dark-gray dark:text-white"
                  >
                    Sign up free
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </AuthLayout>
    </>
  );
}
