import axios from "axios";
import { ROUTERS } from "config/routes";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "http://localhost:4000/api",
  timeout: 50000,
  headers: { "X-Custom-Header": "xxx", "Access-Control-Allow-Origin": "*" },
});

export const request = (options = {}) => {
  const AUTH_TOKEN = `Bearer ${localStorage.getItem("auth")}`;
  // axiosInstance.defaults.headers["Content-Type"] = "application/json";
  axiosInstance.defaults.headers.common.Authorization = AUTH_TOKEN;
  if (options.headers) {
    axiosInstance.defaults.headers = {
      ...axiosInstance.defaults.headers,
      ...options.headers,
    };
  }

  return axiosInstance;
};

export const parseErrorResponse = (err) => {
  if (err && err.response) {
    if (err.response.data.code === 401) {
      if (err.response.config.method === "post") {
        return window.location.replace(ROUTERS.LOGIN);
      }
      //   cookies.remove('authorization');
    }
    return err.response.data;
  }
  return new Error("Bad request");
};
