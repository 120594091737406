import React, { useEffect, useState } from "react";
import DarkModeContext from "../Contexts/DarkModeContext";

function Default({ children }) {
  // dark mode setup
  const [theme, setTheme] = useState(null);
  const theme_save = localStorage.getItem("theme");

  useEffect(() => {
    if (theme_save) {
      setTheme(theme_save);
    } else {
      setTheme("dark");
    }
  }, [theme_save]);

  useEffect(() => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [theme]);

  const handleThemeSwitch = () => {
    localStorage.setItem("theme", theme === "dark" ? "light" : "dark");
    setTheme(theme === "dark" ? "light" : "dark");
  };

  const colors = {
    yellow_600: "#ecc101",
    yellow_300: "#fef885",
    blue_600: "#0015b5",
    blue_300: "#046ce6",
    blue_100 : "#028ffa"
  };

  return (
    <>
      <DarkModeContext.Provider value={{ theme, colors, handleThemeSwitch }}>
        {children && children}
      </DarkModeContext.Provider>
    </>
  );
}

export default Default;
