/* eslint-disable react/button-has-type */

import ModalCom from "components/Helpers/ModalCom";
import { GlobalContext } from "contexts/GlobalContext";
import { useContext, useMemo } from "react";
import { formatNumber } from "utils/common";

export default function ModalDetailBalances({ showModal, setShowModal }) {
  const { auth, balance: balanceCtx, profitBalance: profitBalanceCtx, salaryAndCommissionBalance: salaryAndCommissionBalanceCtx, depositBalance: depositBalanceCtx, parameter } = useContext(GlobalContext);

  const salaryAndCommissionBalance = useMemo(() => {
    if (salaryAndCommissionBalanceCtx) {
      return formatNumber(+(salaryAndCommissionBalanceCtx.tsib || 0));
    }
    return 0;
  }, [salaryAndCommissionBalanceCtx]);

  const depositBalance = useMemo(() => {
    if (depositBalanceCtx) {
      return formatNumber(+(depositBalanceCtx.tsib || 0));
    }
    return 0;
  }, [depositBalanceCtx]);

  const oldBalance = useMemo(() => {
    if (balanceCtx) {
      return formatNumber(+(balanceCtx.tsib || 0));
    }
    return 0;
  }, [balanceCtx]);

  const profitBalance = useMemo(() => {
    if (profitBalanceCtx) {
      return formatNumber(+(profitBalanceCtx.tsib || 0));
    }
    return 0;
  }, [profitBalanceCtx]);  

  return (
    <>
      {showModal && (
        <ModalCom action={() => setShowModal(false)} situation={showModal}>
          <div className="logout-modal-wrapper w-[300px] lg:h-auto bg-white dark:bg-dark-white lg:rounded-2xl">
            <div className="logout-modal-body w-full flex flex-col items-center px-10 py-8">
              <div className="mb-6">
              <div className="lg:flex user-balance cursor-pointer lg:w-[180px] h-[48px]  items-center rounded-full relative  bg-purple pr-1.5 pl-4">
                <div className="flex items-center justify-start w-full h-full gap-3">
                  <div className="ml-2">
                    <p className="text-xs text-white">Balance:</p>
                    <p className="lg:text-xl text-lg font-bold text-white w-full">
                      {oldBalance}
                    </p>
                  </div>
                </div>
              </div>

              <div className="lg:flex user-balance cursor-pointer lg:w-[180px] h-[48px]  items-center rounded-full relative  bg-purple pr-1.5 pl-4">
                <div className="flex items-center justify-start w-full h-full gap-3">
                  <div className="ml-2">
                    <p className="text-xs text-white">Deposit:</p>
                    <p className="lg:text-xl text-lg font-bold text-white w-full">
                      {depositBalance}
                    </p>
                  </div>
                </div>
              </div>

              <div className="lg:flex user-balance cursor-pointer lg:w-[180px] h-[48px]  items-center rounded-full relative  bg-purple pr-1.5 pl-4">
                <div className="flex items-center justify-start w-full h-full gap-3">
                  <div className="ml-2">
                    <p className="text-xs text-white">Profit:</p>
                    <p className="lg:text-xl text-lg font-bold text-white w-full">
                      {profitBalance}
                    </p>
                  </div>
                </div>
              </div>

              <div className="lg:flex user-balance cursor-pointer lg:w-[180px] h-[48px]  items-center rounded-full relative  bg-purple pr-1.5 pl-4">
                <div className="flex items-center justify-start w-full h-full gap-3">
                  <div className="ml-2">
                    <p className="text-xs text-white">Salary and commission:</p>
                    <p className="lg:text-xl text-lg font-bold text-white w-full">
                      {salaryAndCommissionBalance}
                    </p>
                  </div>
                </div>
              </div>
              </div>
              <div className="flex space-x-2.5">
                {/* <button
                  onClick={() => handleConfirm()}
                  type="button"
                  className="text-white primary-gradient text-18 tracking-wide px-7 py-2 rounded-full"
                >
                  Yes
                </button> */}
                <button
                  onClick={() => setShowModal(false)}
                  type="button"
                  className=" border-gradient text-18 tracking-wide px-7 py-2 rounded-full"
                >
                  <span className="text-gradient">Close</span>
                </button>
              </div>
            </div>
          </div>
        </ModalCom>
      )}
    </>
  );
}
