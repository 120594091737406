import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import titleShape from "assets/images/shape/text-shape-three.svg";
import AuthService from "services/auth";
import useQuery from "hooks/useQuery";
import Button from "components/Helpers/Button";
import { ROUTERS } from "config/routes";

import Otp from "./Otp";
import AuthLayout from "../AuthLayout";

export default function VerifyYou() {
  const { type, email, token } = useQuery();
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);

  const verify = async () => {
    try {
      switch (type) {
        case "signup": {
          setLoading(true);
          const res = await AuthService.verifyEmail({
            body: {
              otp,
              email,
            },
          });
          if (res && res.token) {
            localStorage.setItem("auth", res.token);
            setLoading(false);
            navigate(ROUTERS.DASHBOARD);
          } else toast.error("Otp incorrect!");
          break;
        }
        case "update-password": {
          navigate(`${ROUTERS.UPDATE_PASSWORD}?otp=${otp}&token=${token}`);
          break;
        }
        default:
          break;
      }
    } catch (error) {
      setLoading(false);
      toast.error("Otp incorrect!");
    }
  };

  const resendCode = async () => {
    try {
      await AuthService.resendCode({
        body: {
          email,
        },
      });
      toast.success("Resend success");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <AuthLayout slogan="Welcome to  Tosi Bank">
        <div className="content-wrapper xl:bg-white dark:bg-dark-white   w-full sm:w-auto px-5 xl:px-[70px] 2xl:px-[100px] h-[818px] rounded-xl flex flex-col justify-center">
          <div>
            <div className="title-area flex flex-col justify-center items-center relative text-center mb-8">
              <h1 className="sm:text-5xl text-4xl font-bold leading-[74px]   text-dark-gray dark:text-white">
                Verification Code
              </h1>
              <div className="shape sm:w-[377px] w-[270px] -mt-5 ml-5">
                <img src={titleShape} alt="shape" />
              </div>
            </div>
            <div className="input-area">
              <Otp setOtp={setOtp} otp={otp} />
              <div className="signin-area mb-3.5">
                <Button
                  className="w-full rounded-[50px] h-[58px] mb-6 text-xl text-white font-bold flex justify-center bg-purple items-center"
                  onClick={verify}
                  loading={loading}
                >
                  Continue
                </Button>
              </div>
              <div className="resend-code flex justify-center">
                <p className="text-lg text-thin-light-gray font-normal">
                  Dont’t have an code ?
                  <a
                    onClick={(_) => resendCode()}
                    className="ml-2 text-dark-gray dark:text-white font-bold"
                  >
                    Please resend
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </AuthLayout>
    </>
  );
}
