import { GlobalContext } from "contexts/GlobalContext";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { formatDate, formatNumber } from "utils/common";
import { INVEST_STYLE_STATUS } from "utils/constant";
import DataIteration from "../Helpers/DataIteration";
import SectionTitle from "../Helpers/SectionTitle";
import ModalInvestDetail from "./ModalInvest/ModalInvestDetail";

export default function History({ data, getInvestHistories }) {
  const { system } = useContext(GlobalContext);
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [seletedItem, setSeletedItem] = useState(null);

  return (
    <div className="mb-5">
      <SectionTitle name="History" className="mb-10" />
      <div
        className={`update-table w-full py-8 sm:px-8 px-3 bg-white dark:bg-blue-9   overflow-hidden rounded-2xl section-shadow min-h-[520px]`}
      >
        <div className="relative w-full overflow-x-auto sm:rounded-lg">
          <table className="history-invest-table w-full text-dark-gray dark:text-white tracking-wide overflow-hidden border-spacing-y-2 border-separate">
            <thead className="rounded-md">
              <tr className="text-sm text-thin-light-gray whitespace-nowrap border-b dark:border-[#5356fb29] default-border-b ">
                <th className="p-3">#</th>
                <th className="p-3">Status</th>
                <th className="p-3">Value</th>
                <th className="p-3">Payback</th>
                <th className="p-3">Remain days</th>
                <th className="p-3">Daily profit</th>
                <th className="p-3">Total profit</th>
                <th className="p-3">Time</th>
              </tr>
            </thead>
            <tbody>
              <DataIteration
                datas={data}
                startLength={0}
                endLength={data.length}
              >
                {({ datas }) => (
                  <tr
                    className="text-center cursor-pointer"
                    onClick={() => {
                      setSeletedItem(datas);
                      setOpenModal(true);
                    }}>
                    <td className="p-0">
                      <div className="bg-gray-blue h-[60px] flex justify-center items-center rounded-l-[14px] text-sm">
                        {datas._id}
                      </div>
                    </td>
                    <td className="p-0">
                      <div className="bg-gray-blue h-[60px] flex justify-center items-center">
                        <button type="button" className={INVEST_STYLE_STATUS[datas.status][0] + " w-max"}>
                          {INVEST_STYLE_STATUS[datas.status][1]}
                        </button>
                      </div>
                    </td>
                    <td className="p-0">
                      <div className="bg-gray-blue h-[60px] flex justify-center items-center">
                        <div>
                          <span className="text-sm">
                            {formatNumber(datas.investment.amount)}{" "}
                          </span>
                          <span className="text-[10px]">{system.token}</span>
                        </div>
                      </div>
                    </td>
                    <td className="p-0">
                      <div className="bg-gray-blue h-[60px] flex justify-center items-center text-sm">
                        {datas.investment.time_lock} Days
                      </div>
                    </td>
                    <td className="p-0">
                      <div className="bg-gray-blue h-[60px] flex justify-center items-center text-sm">
                        {datas.investment.time_lock - datas?.investment?.number_unlock || 0} Days
                      </div>
                    </td>
                    <td className="p-0">
                      <div className="bg-gray-blue h-[60px] flex justify-center items-center text-[#4caf50]">
                        <div>
                          <span className="text-sm">
                            {formatNumber(datas.investment.daily_profit)}{" "}
                          </span>
                          <span className="text-[10px]">{system.token}</span>
                        </div>
                      </div>
                    </td>
                    <td className="p-0">
                      <div className="bg-gray-blue h-[60px] flex justify-center items-center">
                        <div>
                          <span className="text-sm">
                            {formatNumber(datas.investment.total_profit)}{" "}
                          </span>
                          <span className="text-[10px]">{system.token}</span>
                        </div>
                      </div>
                    </td>
                    <td className="p-0">
                      <div className="bg-gray-blue h-[60px] flex justify-center items-center rounded-r-[14px] text-sm">
                        {formatDate(datas.createdAt)}
                      </div>
                    </td>
                  </tr>
                )}
              </DataIteration>
            </tbody>
          </table>
        </div>
      </div>
      <ModalInvestDetail
        setShowModal={setOpenModal}
        showModal={openModal}
        seletedItem={seletedItem}
        callback={getInvestHistories}
      />
    </div >
  );
}
