import React from 'react';
import LevelUser from './LevelUser';

const levels = [
    'MEMBER',
    'BEGINNER',
    'SILVER',
    'GOLD',
    'RUBY',
    'DIAMOND',
    'CROWN',
    'FOUNDER RUBY',
    'FOUNDER DIAMOND',
    'FOUNDER CROWN'
];

const LevelList = ({ currentLevel }) => (
        <div className='2xl:h-[140px] lg:h-[200px] h-[180px] overflow-y-auto no-scrollbar' id='level-list'>
            {levels.map((level) => (
                <LevelUser key={level} level={level} currentLevel={currentLevel}/>
            ))}
        </div>
);

export default LevelList;