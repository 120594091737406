
export default function TermAndCondition() {
  return (
    <div className="container mx-auto my-[100px]">
      <h1 className="mb-10 text-[50px] font-bold text-white px-8">LEGAL DISCLAIMER</h1>

      <div className="flex flex-col xl:flex-row mb-20">
        <div className="xl:w-1/2 text-white px-8">
          <div className="mb-7 flex flex-row items-center gap-2">
            <div className="w-[14px] min-w-[14px] h-[14px] bg-[#FCB711] rounded-full"></div>
            <p className="text-xl">
              Please read the entirety of this section carefully
            </p>
          </div>
          <p className="mb-10 text-gray-60">
            Nothing in this
            whitepaper constitutes investment, legal or business advice and you
            should consult your own advisor(s) before engaging in any activity
            in connection with the tosi (tsi) the company, any of the members
            nor any service providers who have worked on the tosi project in any
            way whatsoever shall be liable for any kind of direct or indirect
            damages or losses whatsoever which you may suffer in connection with
            accessing this whitepaper, the website at <a className="underline text-white " href="https://tsijp.jp">https://tsijp.jp</a> (the
            “website”) or any other websites or materials published by the
            company
          </p>

          <div className="mb-7 flex flex-row items-center gap-2">
            <div className="w-[14px] min-w-[14px] h-[14px] bg-[#FCB711] rounded-full"></div>
            <p className="text-xl">
              This Whitepaper is intended for general informational purposes only
              and does not constitute a prospectus, an o¬er of TOSI or securities,
              a solicitation for investment, nor an initiation for an o¬er to
              exchange any amount of cryptocurrency for TOSI.
            </p>
          </div>

          <p className="mb-5 text-gray-60">
            This Whitepaper
            audits and analyzes certain aspects of TOSI and the information
            stated herein is not legally binding and the study focuses
            exclusively on the activities and assets of TOSI.
          </p>

          <p className="mb-5 text-gray-60">
            For a description
            of the risks associated with the TOSI enterprise, please see the
            Whitepaper section entitled “Risk Factors”.
          </p>

          <p className="mb-5 text-gray-60">
            The information herein
            below may not be exhaustive and does not imply any elements of a
            contractual relationship. The Company does not and does not purport
            to make, and hereby expressly disclaims, all representations,
            warranties or undertaking (including without limitation warranties
            as to the accuracy, completeness, timeliness or reliability of the
            contents of this Whitepaper or any other materials published by the
            Company) to any person or entity.
          </p>

          <p className="mb-5 text-gray-60">
            To the maximum extent permitted by
            law, the Company shall not be liable for any indirect, special,
            incidental, consequential or other losses of any kind, in tort,
            contract or otherwise (including, without limitation, any liability
            arising from default or negligence on the part of any of them, or
            any loss of revenue, income or profits, and loss of use or data)
            arising from the use of this Whitepaper or any other materials
            published, or its contents (including without limitation any errors
            or omissions) or otherwise arising in connection with the same.
          </p>

          <p className="mb-5 text-gray-60">
            Nothing contained in this Whitepaper is or may be relied upon as a
            promise, representation or undertaking as to the future performance
            of TOSI.
          </p>

          <p className="mb-5 text-gray-60">
            Where this Whitepaper includes information that has been
            obtained from third party sources, the Company has not independently
            verified the accuracy or completion of such information.
          </p>

          <p className="mb-5 text-gray-60">
            Further,
            due to a change of circumstances this Whitepaper may become amended
            or replaced from time to time and in that event there are no
            obligations to update this Whitepaper or to provide recipients with
            access to any information beyond what is provided in this
            Whitepaper. Readers should monitor our Website closely for any
            updates
          </p>

          <div className="mb-5">
            <img src={'assets/images/term_image_1.png'} alt="term" className="mx-auto" />
          </div>

          <p className="mb-10 text-white xl:mb-5">
            This White Paper has not been approved by an authorised person. Any
            information to which this document relates is available only to a
            relevant person. This White Paper is only for relevant persons and
            non-relevant persons shall not take any action based on this
            document nor should he/she/they rely on it. It is a condition of you
            receiving and retaining this document that you warrant to the
            Company, its directors, and its o cers that you are a relevant
            person. For the avoidance of doubt, under no circumstances would a
            holder of TOSI be entitled to or receive (i) any form of interests
            whatsoever in whole or in part (including but not limited to equity
            interest, proprietary interest and ownership interest) or share in
            or claim against the Company and/or the cryptocurrency industrial
            mining operation, (ii) any voting rights at the board meetings or
            shareholders meetings of the Company and/or the cryptocurrency
            industrial mining operation, (iii) any management rights of the
            Company and/or the cryptocurrency industrial mining operation, (iv)
            any economic right (including any kind of right to payment,
            distribution, income, dividend, profit, or other return, or any sums
            to be paid, or likely to be paid, out of such return or profit) of
            or generated by the Company and/or the cryptocurrency industrial
            mining operation, and (v) any interest payments from the Company
            and/or the cryptocurrency industrial mining operation. No part of
            this Whitepaper is to be copied, reproduced, distributed or
            disseminated in any way without the prior written consent of the
            Company.
          </p>

        </div>
        <div className="xl:w-1/2 text-white px-8 overflow-auto xl:max-h-full sm:max-h-[600px] max-h-[400px]">
          <div className="bg-blue-9 flex flex-row items-start py-7 px-8 rounded-[33px] gap-x-3.5 mb-5 border border-white-12">
            <div className="w-[26px] min-w-[26px] rounded-full h-[26px] primary-auth-gradient-number p-px">
              <div className="w-full h-full rounded-full bg-blue-9 text-center">
                <span className="text-[#FCB711]">1</span>
              </div>
            </div>
            <p>
              Any person’s purchase of the TOSI in violation of any anti-money
              laundering, counter-terrorism financing or other regulatory
              requirements that are imposed in any jurisdiction;{" "}
            </p>
          </div>
          <div className="bg-blue-9 flex flex-row items-start py-7 px-8 rounded-[33px] gap-x-3.5 mb-5 border border-white-12">
            <div className="w-[26px] min-w-[26px] rounded-full h-[26px] primary-auth-gradient-number p-px">
              <div className="w-full h-full rounded-full bg-blue-9 text-center">
                <span className="text-[#FCB711]">2</span>
              </div>
            </div>
            <p>
              Any person’s purchase of the TOSI in violation of any
              representation, warranty, obligation, covenant or other provision
              under this White Paper, which results in the failure of paying and
              withdrawing the TOSI;{" "}
            </p>
          </div>
          <div className="bg-blue-9 flex flex-row items-start py-7 px-8 rounded-[33px] gap-x-3.5 mb-5 border border-white-12">
            <div className="w-[26px] min-w-[26px] rounded-full h-[26px] primary-auth-gradient-number p-px">
              <div className="w-full h-full rounded-full bg-blue-9 text-center">
                <span className="text-[#FCB711]">3</span>
              </div>
            </div>
            <p>
              Failure or termination of the application development which
              results in the failure to deliver the TOSI;{" "}
            </p>
          </div>
          <div className="bg-blue-9 flex flex-row items-start py-7 px-8 rounded-[33px] gap-x-3.5 mb-5 border border-white-12">
            <div className="w-[26px] min-w-[26px] rounded-full h-[26px] primary-auth-gradient-number p-px">
              <div className="w-full h-full rounded-full bg-blue-9 text-center">
                <span className="text-[#FCB711]">4</span>
              </div>
            </div>
            <p>
              Delay or rescheduling of the application development and
              resulting failure to meet any published schedules;{" "}
            </p>
          </div>
          <div className="bg-blue-9 flex flex-row items-start py-7 px-8 rounded-[33px] gap-x-3.5 mb-5 border border-white-12">
            <div className="w-[26px] min-w-[26px] rounded-full h-[26px] primary-auth-gradient-number p-px">
              <div className="w-full h-full rounded-full bg-blue-9 text-center">
                <span className="text-[#FCB711]">5</span>
              </div>
            </div>
            <p>
              Any errors, flaws, defects or other issues in the source code of
              the TOSI;{" "}
            </p>
          </div>
          <div className="bg-blue-9 flex flex-row items-start py-7 px-8 rounded-[33px] gap-x-3.5 mb-5 border border-white-12">
            <div className="w-[26px] min-w-[26px] rounded-full h-[26px] primary-auth-gradient-number p-px">
              <div className="w-full h-full rounded-full bg-blue-9 text-center">
                <span className="text-[#FCB711]">6</span>
              </div>
            </div>
            <p>
              Any malfunction, breakdown, collapse, rollback or hard forking of
              the original public chain that the TOSI rely on;{" "}
            </p>
          </div>
          <div className="bg-blue-9 flex flex-row items-start py-7 px-8 rounded-[33px] gap-x-3.5 mb-5 border border-white-12">
            <div className="w-[26px] min-w-[26px] rounded-full h-[26px] primary-auth-gradient-number p-px">
              <div className="w-full h-full rounded-full bg-blue-9 text-center">
                <span className="text-[#FCB711]">7</span>
              </div>
            </div>
            <p>
              Failure of the TOSI to meet any specific purpose or its unfitness
              for any specific use;{" "}
            </p>
          </div>
          <div className="bg-blue-9 flex flex-row items-start py-7 px-8 rounded-[33px] gap-x-3.5 mb-5 border border-white-12">
            <div className="w-[26px] min-w-[26px] rounded-full h-[26px] primary-auth-gradient-number p-px">
              <div className="w-full h-full rounded-full bg-blue-9 text-center">
                <span className="text-[#FCB711]">8</span>
              </div>
            </div>
            <p>
              Failure to promptly and completely disclose any information
              relating to the development of TOSI;{" "}
            </p>
          </div>
          <div className="bg-blue-9 flex flex-row items-start py-7 px-8 rounded-[33px] gap-x-3.5 mb-5 border border-white-12">
            <div className="w-[26px] min-w-[26px] rounded-full h-[26px] primary-auth-gradient-number p-px">
              <div className="w-full h-full rounded-full bg-blue-9 text-center">
                <span className="text-[#FCB711]">9</span>
              </div>
            </div>
            <p>
              Any person’s divulgence, loss or destruction of the private key
              to his/her wallet for cryptocurrency or cryptographic (in particular
              the private key to the TOSI wallet);
            </p>
          </div>
          <div className="bg-blue-9 flex flex-row items-start py-7 px-8 rounded-[33px] gap-x-3.5 mb-5 border border-white-12">
            <div className="w-[26px] min-w-[26px] rounded-full h-[26px] primary-auth-gradient-number p-px">
              <div className="w-full h-full rounded-full bg-blue-9 text-center">
                <span className="text-[#FCB711]">10</span>
              </div>
            </div>
            <p>
              Any default, breach, infringement, breakdown, collapse, service
              suspension or interruption, fraud, mishandling, misconduct,
              malpractice, negligence, bankruptcy, insolvency, dissolution or
              winding-up of any third-party platform or exchange (if any) for
              TOSI;
            </p>
          </div>
          <div className="bg-blue-9 flex flex-row items-start py-7 px-8 rounded-[33px] gap-x-3.5 mb-5 border border-white-12">
            <div className="w-[26px] min-w-[26px] rounded-full h-[26px] primary-auth-gradient-number p-px">
              <div className="w-full h-full rounded-full bg-blue-9 text-center">
                <span className="text-[#FCB711]">11</span>
              </div>
            </div>
            <p>
              Any diference, conflict or contradiction between this White
              Paper and the agreement between any person and any third-party
              portal;
            </p>
          </div>
          <div className="bg-blue-9 flex flex-row items-start py-7 px-8 rounded-[33px] gap-x-3.5 mb-5 border border-white-12">
            <div className="w-[26px] min-w-[26px] rounded-full h-[26px] primary-auth-gradient-number p-px">
              <div className="w-full h-full rounded-full bg-blue-9 text-center">
                <span className="text-[#FCB711]">12</span>
              </div>
            </div>
            <p>
              Trading or speculation of the TOSI by any person;
            </p>
          </div>
          <div className="bg-blue-9 flex flex-row items-start py-7 px-8 rounded-[33px] gap-x-3.5 mb-5 border border-white-12">
            <div className="w-[26px] min-w-[26px] rounded-full h-[26px] primary-auth-gradient-number p-px">
              <div className="w-full h-full rounded-full bg-blue-9 text-center">
                <span className="text-[#FCB711]">13</span>
              </div>
            </div>
            <p>
              Listing or delisting of the TOSI on or from any exchange;{" "}
            </p>
          </div>
          <div className="bg-blue-9 flex flex-row items-start py-7 px-8 rounded-[33px] gap-x-3.5 mb-5 border border-white-12">
            <div className="w-[26px] min-w-[26px] rounded-full h-[26px] primary-auth-gradient-number p-px">
              <div className="w-full h-full rounded-full bg-blue-9 text-center">
                <span className="text-[#FCB711]">14</span>
              </div>
            </div>
            <p>
              The TOSI being classified or treated by any government,
              quasi-government, authority or public body as a type of currency,
              securities, commercial paper, negotiable instrument, investment
              instrument or otherwise that results in it being banned, regulated
              or subject to certain legal restrictions;{" "}
            </p>
          </div>
          <div className="bg-blue-9 flex flex-row items-start py-7 px-8 rounded-[33px] gap-x-3.5 mb-5 border border-white-12">
            <div className="w-[26px] min-w-[26px] rounded-full h-[26px] primary-auth-gradient-number p-px">
              <div className="w-full h-full rounded-full bg-blue-9 text-center">
                <span className="text-[#FCB711]">15</span>
              </div>
            </div>
            <p>
              Any damage, loss, claim, liability, punishment, cost or other
              adverse impact that is caused by, associated with, in connection
              with, incidental to or relevant to the TOSI.
            </p>
          </div>
        </div>
      </div>

      <h1 className="mb-10 text-[50px] font-bold text-white px-8">RISK FACTORS</h1>

      <div className="flex flex-col xl:flex-row xl:mb-20 mb-16">
        <div className="xl:w-1/2 text-white px-8 overflow-y-scroll md:max-h-[600px] max-h-[400px] mb-10 xl:mb-0">
          <div className="bg-blue-9 flex flex-row items-start py-7 px-8 rounded-[33px] gap-x-3.5 mb-5 border border-white-12">
            <div className="w-[26px] min-w-[26px] rounded-full h-[26px] primary-auth-gradient-number p-px">
              <div className="w-full h-full rounded-full bg-blue-9 text-center">
                <span className="text-[#FCB711]">1</span>
              </div>
            </div>
            <p>
              Amendment or termination of the campaign
            </p>
          </div>
          <div className="bg-blue-9 flex flex-row items-start py-7 px-8 rounded-[33px] gap-x-3.5 mb-5 border border-white-12">
            <div className="w-[26px] min-w-[26px] rounded-full h-[26px] primary-auth-gradient-number p-px">
              <div className="w-full h-full rounded-full bg-blue-9 text-center">
                <span className="text-[#FCB711]">2</span>
              </div>
            </div>
            <p>
              Limited availability of sufficient information
            </p>
          </div>
          <div className="bg-blue-9 flex flex-row items-start py-7 px-8 rounded-[33px] gap-x-3.5 mb-5 border border-white-12">
            <div className="w-[26px] min-w-[26px] rounded-full h-[26px] primary-auth-gradient-number p-px">
              <div className="w-full h-full rounded-full bg-blue-9 text-center">
                <span className="text-[#FCB711]">3</span>
              </div>
            </div>
            <p>
              Regulatory measures
            </p>
          </div>
          <div className="bg-blue-9 flex flex-row items-start py-7 px-8 rounded-[33px] gap-x-3.5 mb-5 border border-white-12">
            <div className="w-[26px] min-w-[26px] rounded-full h-[26px] primary-auth-gradient-number p-px">
              <div className="w-full h-full rounded-full bg-blue-9 text-center">
                <span className="text-[#FCB711]">4</span>
              </div>
            </div>
            <p>
              Cryptographic protections
            </p>
          </div>
          <div className="bg-blue-9 flex flex-row items-start py-7 px-8 rounded-[33px] gap-x-3.5 mb-5 border border-white-12">
            <div className="w-[26px] min-w-[26px] rounded-full h-[26px] primary-auth-gradient-number p-px">
              <div className="w-full h-full rounded-full bg-blue-9 text-center">
                <span className="text-[#FCB711]">5</span>
              </div>
            </div>
            <p>
              Abandonment or development failure
            </p>
          </div>
          <div className="bg-blue-9 flex flex-row items-start py-7 px-8 rounded-[33px] gap-x-3.5 mb-5 border border-white-12">
            <div className="w-[26px] min-w-[26px] rounded-full h-[26px] primary-auth-gradient-number p-px">
              <div className="w-full h-full rounded-full bg-blue-9 text-center">
                <span className="text-[#FCB711]">6</span>
              </div>
            </div>
            <p>
              Compromised security
            </p>
          </div>
          <div className="bg-blue-9 flex flex-row items-start py-7 px-8 rounded-[33px] gap-x-3.5 mb-5 border border-white-12">
            <div className="w-[26px] min-w-[26px] rounded-full h-[26px] primary-auth-gradient-number p-px">
              <div className="w-full h-full rounded-full bg-blue-9 text-center">
                <span className="text-[#FCB711]">7</span>
              </div>
            </div>
            <p>
              Unauthorized claim of Tosi
            </p>
          </div>
          <div className="bg-blue-9 flex flex-row items-start py-7 px-8 rounded-[33px] gap-x-3.5 mb-5 border border-white-12">
            <div className="w-[26px] min-w-[26px] rounded-full h-[26px] primary-auth-gradient-number p-px">
              <div className="w-full h-full rounded-full bg-blue-9 text-center">
                <span className="text-[#FCB711]">8</span>
              </div>
            </div>
            <p>
              Loss of private key
            </p>
          </div>
          <div className="bg-blue-9 flex flex-row items-start py-7 px-8 rounded-[33px] gap-x-3.5 mb-5 border border-white-12">
            <div className="w-[26px] min-w-[26px] rounded-full h-[26px] primary-auth-gradient-number p-px">
              <div className="w-full h-full rounded-full bg-blue-9 text-center">
                <span className="text-[#FCB711]">9</span>
              </div>
            </div>
            <p>
              Forking
            </p>
          </div>
          <div className="bg-blue-9 flex flex-row items-start py-7 px-8 rounded-[33px] gap-x-3.5 mb-5 border border-white-12">
            <div className="w-[26px] min-w-[26px] rounded-full h-[26px] primary-auth-gradient-number p-px">
              <div className="w-full h-full rounded-full bg-blue-9 text-center">
                <span className="text-[#FCB711]">10</span>
              </div>
            </div>
            <p>
              Popularity
            </p>
          </div>
          <div className="bg-blue-9 flex flex-row items-start py-7 px-8 rounded-[33px] gap-x-3.5 mb-5 border border-white-12">
            <div className="w-[26px] min-w-[26px] rounded-full h-[26px] primary-auth-gradient-number p-px">
              <div className="w-full h-full rounded-full bg-blue-9 text-center">
                <span className="text-[#FCB711]">11</span>
              </div>
            </div>
            <p>
              Market liquidity
            </p>
          </div>
          <div className="bg-blue-9 flex flex-row items-start py-7 px-8 rounded-[33px] gap-x-3.5 mb-5 border border-white-12">
            <div className="w-[26px] min-w-[26px] rounded-full h-[26px] primary-auth-gradient-number p-px">
              <div className="w-full h-full rounded-full bg-blue-9 text-center">
                <span className="text-[#FCB711]">12</span>
              </div>
            </div>
            <p>
              Price volatility
            </p>
          </div>
          <div className="bg-blue-9 flex flex-row items-start py-7 px-8 rounded-[33px] gap-x-3.5 mb-5 border border-white-12">
            <div className="w-[26px] min-w-[26px] rounded-full h-[26px] primary-auth-gradient-number p-px">
              <div className="w-full h-full rounded-full bg-blue-9 text-center">
                <span className="text-[#FCB711]">13</span>
              </div>
            </div>
            <p>
              The company’s exposure to cryptographic tokens
            </p>
          </div>
          <div className="bg-blue-9 flex flex-row items-start py-7 px-8 rounded-[33px] gap-x-3.5 mb-5 border border-white-12">
            <div className="w-[26px] min-w-[26px] rounded-full h-[26px] primary-auth-gradient-number p-px">
              <div className="w-full h-full rounded-full bg-blue-9 text-center">
                <span className="text-[#FCB711]">14</span>
              </div>
            </div>
            <p>
              Conflict of interests
            </p>
          </div>
          <div className="bg-blue-9 flex flex-row items-start py-7 px-8 rounded-[33px] gap-x-3.5 mb-5 border border-white-12">
            <div className="w-[26px] min-w-[26px] rounded-full h-[26px] primary-auth-gradient-number p-px">
              <div className="w-full h-full rounded-full bg-blue-9 text-center">
                <span className="text-[#FCB711]">15</span>
              </div>
            </div>
            <p>
              Potential competitors
            </p>
          </div>
          <div className="bg-blue-9 flex flex-row items-start py-7 px-8 rounded-[33px] gap-x-3.5 mb-5 border border-white-12">
            <div className="w-[26px] min-w-[26px] rounded-full h-[26px] primary-auth-gradient-number p-px">
              <div className="w-full h-full rounded-full bg-blue-9 text-center">
                <span className="text-[#FCB711]">16</span>
              </div>
            </div>
            <p>
              Third party developers and suppliers
            </p>
          </div>
          <div className="bg-blue-9 flex flex-row items-start py-7 px-8 rounded-[33px] gap-x-3.5 mb-5 border border-white-12">
            <div className="w-[26px] min-w-[26px] rounded-full h-[26px] primary-auth-gradient-number p-px">
              <div className="w-full h-full rounded-full bg-blue-9 text-center">
                <span className="text-[#FCB711]">17</span>
              </div>
            </div>
            <p>
              Potential misuse of the Tosi’s technologies and brand
            </p>
          </div>
          <div className="bg-blue-9 flex flex-row items-start py-7 px-8 rounded-[33px] gap-x-3.5 mb-5 border border-white-12">
            <div className="w-[26px] min-w-[26px] rounded-full h-[26px] primary-auth-gradient-number p-px">
              <div className="w-full h-full rounded-full bg-blue-9 text-center">
                <span className="text-[#FCB711]">18</span>
              </div>
            </div>
            <p>
              Privacy and data retention issues
            </p>
          </div>
          <div className="bg-blue-9 flex flex-row items-start py-7 px-8 rounded-[33px] gap-x-3.5 mb-5 border border-white-12">
            <div className="w-[26px] min-w-[26px] rounded-full h-[26px] primary-auth-gradient-number p-px">
              <div className="w-full h-full rounded-full bg-blue-9 text-center">
                <span className="text-[#FCB711]">19</span>
              </div>
            </div>
            <p>
              General risks relating to the use of the internet or other electronic medium
            </p>
          </div>
          <div className="bg-blue-9 flex flex-row items-start py-7 px-8 rounded-[33px] gap-x-3.5 mb-5 border border-white-12">
            <div className="w-[26px] min-w-[26px] rounded-full h-[26px] primary-auth-gradient-number p-px">
              <div className="w-full h-full rounded-full bg-blue-9 text-center">
                <span className="text-[#FCB711]">20</span>
              </div>
            </div>
            <p>
              Tax matters relating to the company’s operations
            </p>
          </div>
          <div className="bg-blue-9 flex flex-row items-start py-7 px-8 rounded-[33px] gap-x-3.5 mb-5 border border-white-12">
            <div className="w-[26px] min-w-[26px] rounded-full h-[26px] primary-auth-gradient-number p-px">
              <div className="w-full h-full rounded-full bg-blue-9 text-center">
                <span className="text-[#FCB711]">21</span>
              </div>
            </div>
            <p>
              Tax matters relating to participation in the campaign
            </p>
          </div>
          <div className="bg-blue-9 flex flex-row items-start py-7 px-8 rounded-[33px] gap-x-3.5 mb-5 border border-white-12">
            <div className="w-[26px] min-w-[26px] rounded-full h-[26px] primary-auth-gradient-number p-px">
              <div className="w-full h-full rounded-full bg-blue-9 text-center">
                <span className="text-[#FCB711]">22</span>
              </div>
            </div>
            <p>
              Personal connections with particular jurisdictions
            </p>
          </div>
          <div className="bg-blue-9 flex flex-row items-start py-7 px-8 rounded-[33px] gap-x-3.5 mb-5 border border-white-12">
            <div className="w-[26px] min-w-[26px] rounded-full h-[26px] primary-auth-gradient-number p-px">
              <div className="w-full h-full rounded-full bg-blue-9 text-center">
                <span className="text-[#FCB711]">23</span>
              </div>
            </div>
            <p>
              Further token sales and development and sale of additional tokens
            </p>
          </div>
          <div className="bg-blue-9 flex flex-row items-start py-7 px-8 rounded-[33px] gap-x-3.5 mb-5 border border-white-12">
            <div className="w-[26px] min-w-[26px] rounded-full h-[26px] primary-auth-gradient-number p-px">
              <div className="w-full h-full rounded-full bg-blue-9 text-center">
                <span className="text-[#FCB711]">24</span>
              </div>
            </div>
            <p>
              Failure to obtain licences
            </p>
          </div>
          <div className="bg-blue-9 flex flex-row items-start py-7 px-8 rounded-[33px] gap-x-3.5 mb-5 border border-white-12">
            <div className="w-[26px] min-w-[26px] rounded-full h-[26px] primary-auth-gradient-number p-px">
              <div className="w-full h-full rounded-full bg-blue-9 text-center">
                <span className="text-[#FCB711]">25</span>
              </div>
            </div>
            <p>
              Disclaimer
            </p>
          </div>
        </div>
        <div className="xl:w-1/2 text-white px-8">
          <div className="mb-7 flex flex-row items-center gap-2">
            <div className="w-[14px] min-w-[14px] h-[14px] bg-[#FCB711] rounded-full"></div>
            <p className="text-xl">
              There are a number of risks involved with the TOSI
            </p>
          </div>
          <p className="mb-10 text-gray-60">
            In future development, maintenance and running of TOSI. Some of these risks are beyond the control of the Company.
          </p>

          <div className="mb-7 flex flex-row items-center gap-2">
            <div className="w-[14px] min-w-[14px] h-[14px] bg-[#FCB711] rounded-full"></div>
            <p className="text-xl">
              Each reader should pay particular attention to the fact
            </p>
          </div>

          <p className="mb-5 text-gray-60">
            The Company is established in Japan, TOSI does not have any specific physical presenc.
          </p>

          <p className="mb-5 text-gray-60">
            The legal and regulatory position of TOSI may vary depending on the facts and circumstances.
          </p>

          <p className="mb-5 text-gray-60">
            They may therefore fall within the purview of one or more jurisdictions, at least to some extent, and the legal and regulatory implications may therefore be unexpected
          </p>

          <p className="mb-5 text-gray-60">
            Acquisition of the TSI shall be taken as an action after careful and prudent analysis and evaluation, and will be deemed as the relevant reader having been fully informed, advised and willing to bear all of the risks associated with it, as set out under this section.
          </p>

          <p className="mb-5 text-gray-60">
            You agree that the Company shall not be liable for, amongst others, any loss in connection with any risk whether disclosed or not disclosed in White Paper. Any such loss will be at your sole and absolute risk.
          </p>
        </div>
      </div>

      <div className="px-8">
        <p className="mb-5 text-white">
          Tosi global is a startup project on blockchain technology - The trend of the future. Participants accompanying tosi global read carefully and clearly understand the necessary information when participating.
        </p>
        <p className="mb-5 text-white">
          1, You agree to lend Tosi Global at the agreed interest rate (Tosi Global is the borrower and you are the lender)
        </p>
        <p className="mb-5 text-white">
          2, Investing and digital currencies can be risky for your assets, please read and understand the content with a spirit of voluntary cooperation and development for mutual benefit.
        </p>
        <p className="mb-5 text-white">
          The above is not an exhaustive list of the risks associated with TOSI and/or the cryptocurrence. Each reader shall familiarize itself with all associated risks prior to acquiring any TSI. Should any reader wish to understand more regarding the risks of TOSI and/or the cryptocurrency, you may wish to reach out a member of the team.
        </p>
      </div>
    </div>
  );
}
