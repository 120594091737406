/* eslint-disable consistent-return */
/* eslint-disable no-empty */
import DarkModeContext from "components/Contexts/DarkModeContext";
import Button from "components/Helpers/Button";
import { GlobalContext } from "contexts/GlobalContext";
import { useContext, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import InvestService from "services/invest";
import { formatNumber } from "utils/common";
import { INVEST_PACKAGE, INVEST_PERIOD } from "utils/constant";
import cryptoCoin from "../../assets/images/crypto-coin.png";
import investChartImage from "../../assets/images/invest_chart.png";
import DataIteration from "../Helpers/DataIteration";
import InvestItem from "./InvestItem";
import ModalConfirmGetOldBalance from "./ModalInvest/ModalConfirmGetOldBalance";

export default function Invests({ periods, getInvestHistories }) {
  const { system, balance: balanceCtx, depositBalance: depositBalanceCtx, getUserAuth, levelInvest, parameter } =
    useContext(GlobalContext);
  const { colors } = useContext(DarkModeContext);

  const [selectedInvestItem, setSelectedInvestItem] = useState(null);
  const [amount, setAmount] = useState();
  const [loading, setLoading] = useState(false);
  const [profit, setProfit] = useState({
    daily: 0,
    total: 0,
  });

  const [confirmModal, setConfirmModal] = useState(false);

  const { count } = parameter;

  const investCategories = [
    {
      id: "1",
      name: "Total investment",
      isShowLogo: true,
      value: formatNumber(count.invest || 0, 2),
      icon: levelInvest?.icon,
    },
    {
      id: "2",
      name: "Daily profit",
      value: formatNumber(count.daily_profit || 0, 2),
      icon: "assets/images/profit_day.svg",
    },
    {
      id: "3",
      name: "Total payback",
      value: formatNumber(count.total_payback || 0, 2),
      icon: "assets/images/total_payback.svg",
    },
  ];

  const profits = [
    {
      id: "1",
      name: "Daily profit",
      value: profit.daily,
      icon: "assets/images/profit_day.svg",
    },
    {
      id: "2",
      name: "Total profit",
      value: profit.total,
      icon: "assets/images/total_payback.svg",
    },
  ];

  const oldBalance = useMemo(() => {
    if (balanceCtx) {
      return balanceCtx.tsib || 0;
    }
    return 0;
  }, [balanceCtx]);

  const depositBalance = useMemo(() => {
    if (depositBalanceCtx) {
      return depositBalanceCtx.tsib || 0;
    }
    return 0;
  }, [depositBalanceCtx]);

  useEffect(() => {
    if (amount > 0 && selectedInvestItem) {
      const period = INVEST_PERIOD.find((e) => e.id === selectedInvestItem);
      setProfit((p) => ({
        daily: formatNumber(period.profit_daily * +amount, 2),
        total: formatNumber(
          period.profit_daily * +amount * period.time * 30,
          2
        ),
      }));
    } else {
      setProfit({
        daily: 0,
        total: 0,
      });
    }
  }, [amount, selectedInvestItem]);

  const selectInvestItemHandler = (id) => {
    setSelectedInvestItem(id);
  };

  const handleSubmit = async () => {
    try {
      setConfirmModal(false);
      setLoading(true);
      const period = INVEST_PERIOD.find((e) => e.id === selectedInvestItem);
      await InvestService.invest({
        body: {
          amount,
          time: period.time,
        },
      });
      setLoading(false);
      setAmount(0);
      setSelectedInvestItem(null);
      getUserAuth();
      getInvestHistories();
      toast.success("Invest success!");
    } catch (error) {
      setLoading(false);
      toast.error(error?.errors);
    }
  };

  const handleSubmitFormInvest = async () => {
    if (!selectedInvestItem) {
      return toast.error("Please choose period");
    }
    if (amount < 500) {
      return toast.error("Amount incorrect");
    }

    if (amount > oldBalance + depositBalance) {
      return toast.error("Not enough balance");
    }

    if (amount > depositBalance) {
      setConfirmModal(true);
    }
    else if (amount <= depositBalance) {
      handleSubmit();
    }
  }

  const handleChangeAmount = (value) => {
    if (+value <= (oldBalance + depositBalance)) setAmount(value);
    else setAmount(oldBalance + depositBalance);
  };

  const levelNext = useMemo(() => {
    if (levelInvest) {
      const _index = INVEST_PACKAGE.findIndex((i) => i.id === levelInvest.id);
      const _level = INVEST_PACKAGE[_index + 1];
      if (_level) {
        return {
          ..._level,
          spend: _level.value - parameter.count.invest,
        };
      }
      return null;
    }
    return {
      ...INVEST_PACKAGE[0],
      spend: INVEST_PACKAGE[0].value,
    };
  }, [levelInvest, parameter]);

  return (
    <div>
      <div className="flex lg:flex-row flex-col gap-10 mb-10">
        <div className="lg:w-2/3 bg-blue-6 rounded-[28px]  border border-t-[2px] border-white-12 p-6">
          <div className="flex flex-col 2xl:flex-row justify-between items-center mb-10">
            <span className="text-white text-[50px] font-bold leading-[50px] 2xl:mb-0 mb-4">Invest</span>
            <div className="flex sm:gap-10 gap-3 sm:flex-row flex-col">
              <div className="flex flex-col">
                <span className="text-gray-60 text-sm">
                  Total investment
                </span>
                <span className="text-white">
                {formatNumber(count.invest || 0, 2)} <span className="text-[10px]">TSIB</span> 
                </span>
              </div>
              <div className="h-[50px] w-[1px] bg-white opacity-50 sm:block hidden"></div>
              <div className="flex flex-col">
                <span className="text-gray-60 text-sm">
                  Daily profit
                </span>
                <span className="text-white">
                  {formatNumber(count.daily_profit || 0, 2)} <span className="text-[10px]">TSIB</span>
                </span>
              </div>
              <div className="h-[50px] w-[1px] bg-white opacity-50 sm:block hidden"></div>
              <div className="flex flex-col">
                <span className="text-gray-60 text-sm">
                  Total payback
                </span>
                <span className="text-white">
                  {formatNumber(count.total_payback || 0, 2)} <span className="text-[10px]">TSIB</span>
                </span>
              </div>
            </div>
          </div>

          <div className="grid md:grid-cols-3 grid-cols-1 gap-5 mb-5">
            <DataIteration
              datas={periods}
              startLength={0}
              endLength={periods.length}
            >
              {({ datas }) => (
                <InvestItem
                  checked={selectedInvestItem === datas.id}
                  id={datas.id}
                  name={datas.name}
                  percent={datas.percent}
                  thumbnail={datas.thumbnail}
                  onSelect={selectInvestItemHandler}
                />
              )}
            </DataIteration>
          </div>
        </div>

        <div className="lg:w-1/3 bg-blue-9 rounded-[28px] border border-t-[2px] border-white-12">
          <div className="px-[22px] py-5 bg-blue-dark rounded-[28px]">
            <div className="flex justify-between mb-3">
              <div className="flex flex-col gap-2.5 mb-10">
                <span className="text-white">Available balance</span>
                <p className="text-yellow-supernova font-medium">
                  <span className="text-2xl mr-1">{formatNumber(oldBalance || 0)}</span>
                  <span className="text-xs">TSIB </span>
                </p>
              </div>
              <img src={investChartImage} alt="" className="w-[60px] h-[60px]" />
            </div>
            <div className="flex flex-col">
              <input
                value={amount}
                className={`w-full px-6 py-4 mt-3 mb-1 outline-none border border-white-12 rounded-[28px] dark:bg-blue-9 dark:text-white placeholder:text-white-12 placeholder:font-normal placeholder:text-sm`}
                placeholder="Amount to invest"
                type="number"
                min={0}
                onChange={(e) => handleChangeAmount(e.target.value)}
              />
              <span className="text-white-30 mb-6">
                * Minimum investment{" "} 500 {system.token}
              </span>
              <Button
                loading={loading}
                disabled={amount < 500 || !selectedInvestItem || loading}
                className={`w-full rounded-full p-2  text-blue-9 text-center flex justify-center capitalize font-semibold text-[15px] items-center  ${amount >= 500 && selectedInvestItem ? "bg-pink" : "bg-white"
                  } `}
                onClick={() => handleSubmitFormInvest()}
              >
                {oldBalance >= 500 ? "Invest" : "Insufficent balance"}
              </Button>
            </div>
          </div>

          <div className="px-[22px] pt-3 pb-[30px] mt-4">
            <div className="flex justify-between items-end mb-3">
              <img src={cryptoCoin} alt="shield" className="w-[45px] h-[45px]" />
              <span className="text-gray-60">The amount you receive</span>
            </div>

            <div className="flex justify-between">
              <div className="flex flex-col">
                <span className="text-white text-sm font-semibold">Daily profit</span>
                <p className="text-yellow-supernova font-medium whitespace-normal text-wrap">
                  <span className="text-[22px] mr-1 break-all">{formatNumber(parameter?.count.revenue_group || 0)}</span>
                  <span className="text-xs">TSIB </span>
                </p>
              </div>

              <div className="flex flex-col">
                <span className="text-white text-sm font-semibold">Total profit</span>
                <p className="text-yellow-supernova font-medium whitespace-normal text-wrap">
                  <span className="text-[22px] mr-1 break-all">{formatNumber(count.total_payback || 0, 2)}</span>
                  <span className="text-xs">TSIB </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalConfirmGetOldBalance
        showModal={confirmModal}
        setShowModal={setConfirmModal}
        handleConfirm={handleSubmit}
        text={`Your deposit balance not enough to invest, do you want to get ${amount - depositBalance}$ from old balance?`}
      />
    </div>
  );
}
