import CommingSoon from "components/CommingSoon";
import { useState } from "react";
import faq from "../../data/faq.json";
import Icons from "../Helpers/Icons";
import Layout from "../Partials/Layout";
import ChangePasswordTab from "./Tabs/ChangePasswordTab";
import PersonalInfoTab from "./Tabs/PersonalInfoTab";
import VerifyAccountTab from "./Tabs/VerifyAccountTab";
import SectionTitle from "components/Helpers/SectionTitle";

export default function Settings() {
  const tabs = [
    {
      id: 1,
      name: "personal",
    },
    {
      id: 2,
      name: "payment",
    },
    {
      id: 3,
      name: "notification",
    },
    {
      id: 4,
      name: "login_activity",
    },
    {
      id: 5,
      name: "password",
    },
    {
      id: 6,
      name: "faq",
    },
    {
      id: 7,
      name: "terms",
    },
  ];
  const [tab, setTab] = useState(tabs[0].name);
  const tabHandler = (value) => {
    setTab(value);
  };

  // fab tab
  const faqData = faq.datas;
  return (
    <>
      <Layout>
        <div className="settings-wrapper w-full relative">
          <div className="main-wrapper w-full">
            {/* heading */}
            <div className="heading w-full mb-6">
              <SectionTitle name="Setting" />
            </div>
            <div className="content-container w-full pt-10 rounded-2xl bg-white dark:bg-blue-dark  ">              
              <div className="content-body w-full lg:flex lg:px-10 px-4">
                <div className="content-tab-items lg:w-[230px] w-full mr-2">
                  <ul className="overflow-hidden mb-10 lg:mb-0">
                    <li
                      onClick={() => tabHandler("personal")}
                      className={`flex lg:space-x-4 space-x-2 hover:text-purple transition-all duration-300 ease-in-out items-center cursor-pointer lg:mb-11 mb-2 mr-6 lg:mr-0 float-left lg:float-none overflow-hidden ${
                        tab === "personal"
                          ? "text-blue-purple"
                          : " text-gray-60"
                      }`}
                    >
                      <div>
                        <Icons name="people-hover" />
                      </div>
                      <div>
                        <p className="tracking-wide">Personal Info</p>
                      </div>
                    </li>

                    <li
                      onClick={() => tabHandler("verify")}
                      className={`flex lg:space-x-4 space-x-2 hover:text-purple transition-all duration-300 ease-in-out items-center cursor-pointer lg:mb-11 mb-2 mr-6 lg:mr-0 float-left lg:float-none overflow-hidden ${
                        tab === "verify"
                          ? "text-blue-purple"
                          : " text-gray-60"
                      }`}
                    >
                      <div>
                        <Icons name="people-hover" />
                      </div>
                      <div>
                        <p className="tracking-wide">Verify Account</p>
                      </div>
                    </li>

                    <li
                      onClick={() => tabHandler("payment")}
                      className={`flex lg:space-x-4 space-x-2 hover:text-purple transition-all duration-300 ease-in-out items-center cursor-pointer lg:mb-11 mb-2 mr-6 lg:mr-0 float-left lg:float-none overflow-hidden ${
                        tab === "payment"
                          ? "text-blue-purple"
                          : " text-gray-60"
                      }`}
                    >
                      <div>
                        <Icons name="bank-card" />
                      </div>
                      <div>
                        <p className="tracking-wide">Payment Method</p>
                      </div>
                    </li>
                    <li
                      onClick={() => tabHandler("notification")}
                      className={`flex lg:space-x-4 space-x-2 hover:text-purple transition-all duration-300 ease-in-out items-center cursor-pointer lg:mb-11 mb-2 mr-6 lg:mr-0 float-left lg:float-none overflow-hidden ${
                        tab === "notification"
                          ? "text-blue-purple"
                          : " text-gray-60"
                      }`}
                    >
                      <div>
                        <Icons name="notification-setting" />
                      </div>
                      <div>
                        <p className="tracking-wide">
                          Notifiction Setting
                        </p>
                      </div>
                    </li>
                    <li
                      onClick={() => tabHandler("login_activity")}
                      className={`flex lg:space-x-4 space-x-2 hover:text-purple transition-all duration-300 ease-in-out items-center cursor-pointer lg:mb-11 mb-2 mr-6 lg:mr-0 float-left lg:float-none overflow-hidden ${
                        tab === "login_activity"
                          ? "text-blue-purple"
                          : " text-gray-60"
                      }`}
                    >
                      <div>
                        <Icons name="login-activity" />
                      </div>
                      <div>
                        <p className="tracking-wide">Login Activity</p>
                      </div>
                    </li>
                    <li
                      onClick={() => tabHandler("password")}
                      className={`flex lg:space-x-4 space-x-2 hover:text-purple transition-all duration-300 ease-in-out items-center cursor-pointer lg:mb-11 mb-2 mr-6 lg:mr-0 float-left lg:float-none overflow-hidden ${
                        tab === "password"
                          ? "text-blue-purple"
                          : " text-gray-60"
                      }`}
                    >
                      <div>
                        <Icons name="password-hover" />
                      </div>
                      <div>
                        <p className="tracking-wide">Change Password</p>
                      </div>
                    </li>
                    {/* <li
                      onClick={() => tabHandler("faq")}
                      className={`flex lg:space-x-4 space-x-2 hover:text-purple transition-all duration-300 ease-in-out items-center cursor-pointer lg:mb-11 mb-2 mr-6 lg:mr-0 float-left lg:float-none overflow-hidden ${
                        tab === "faq" ? "text-blue-purple" : " text-gray-60"
                      }`}
                    >
                      <div>
                        <Icons name="block-question" />
                      </div>
                      <div>
                        <p className="tracking-wide">FAQ</p>
                      </div>
                    </li>
                    <li
                      onClick={() => tabHandler("terms")}
                      className={`flex lg:space-x-4 space-x-2 hover:text-purple transition-all duration-300 ease-in-out items-center cursor-pointer lg:mb-11 mb-2 mr-6 lg:mr-0 float-left lg:float-none overflow-hidden ${
                        tab === "terms"
                          ? "text-blue-purple"
                          : " text-gray-60"
                      }`}
                    >
                      <div>
                        <Icons name="page-right" />
                      </div>
                      <div>
                        <p className="tracking-wide">
                          Terms and Conditions
                        </p>
                      </div>
                    </li> */}
                  </ul>
                </div>
                <div className="w-[1px] bg-[#E3E4FE] dark:bg-[#a7a9b533]  mr-10"></div>
                <div className="content-body-items flex-1">
                  {tab === "personal" && (
                    <div className="tab-item">
                      <PersonalInfoTab />
                    </div>
                  )}
                  {tab === "verify" && (
                    <div className="tab-item">
                      <VerifyAccountTab />
                    </div>
                  )}
                  {tab === "payment" && (
                    <div className="tab-item">
                      {/* <PaymentMathodsTab /> */}
                      <CommingSoon />
                    </div>
                  )}
                  {tab === "notification" && (
                    <div className="tab-item">
                      <CommingSoon />
                      {/* <NotificationSettingTab /> */}
                    </div>
                  )}
                  {tab === "login_activity" && (
                    // <LoginActivityTab />
                    <CommingSoon />
                  )}
                  {tab === "password" && <ChangePasswordTab />}
                  {/* {tab === "faq" && <FaqTab datas={faqData} />}
                  {tab === "terms" && <TermsConditionTab />} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
