/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import background from "../../assets/images/shape/balance-bg.svg";
import { GlobalContext } from "contexts/GlobalContext";
import QRCode from "react-qr-code";
import { IconCommon } from "components/Helpers/Icons";
import { copyText, getAddress, getTextByNumber } from "utils/common";
import { toast } from "react-toastify";

export default function Deposit() {
  const { system, auth, wallet, getWallet } = useContext(GlobalContext);

  useEffect(() => {
    getWallet();
  }, []);

  const handleCopy = (value) => {
    copyText(value);
    toast.success("Copied");
  };

  return (
    <div className="current-balance-widget w-full h-full overflow-hidden flex flex-col px-[22px] py-[26px] bg-blue-6 rounded-[28px] border border-t-[1px] border-white-12">
      <div className="heading md:mb-14 mb-8 flex sm:justify-start justify-center items-center">
        <h3 className="2xl:text-[50px] text-4xl leading-[64px] font-bold   text-dark-gray dark:text-white">
          Deposit
        </h3>
      </div>
      <div className="text-center mb-10">
        <p className="font-bold text-sm uppercase text-dark-gray dark:text-white flex justify-center">
          <IconCommon name="usdt.png" className={"mr-1"} /> Accept only USDT (
          BSC / BEP20 )
        </p>
        <p className="text-gray-60 ">
          Sending any other coin or token to this address may result in the loss
          of your deposit.
        </p>
      </div>
      <div className="flex justify-center mb-6">
        <div className="p-5 bg-white rounded-[28px]">
          {wallet && (
            <QRCode
              title="Wallet"
              value={wallet.address}
              // bgColor={back}
              // fgColor={fore}
              size={200}
            />
          )}
        </div>
      </div>
      <div className="text-center text-dark-gray dark:text-white font-bold mt-5">
        <div className="flex items-center justify-center">
          <p className="url-share mr-2">{getAddress(wallet?.address)}</p>
          <div className="rounded-full bg-[#FCB711] p-3 cursor-pointer">
            <IconCommon
              name="copy-dark.svg"
              width={20}
              height={20}
              onClick={(_) => handleCopy(wallet?.address)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
