import { parseErrorResponse } from "./request";
import { request } from "./request-s3";

const uploadFileS3 = (formData) =>
  new Promise((resolve, reject) => {
    request({
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .post(`uploads/s3/prod-tosi-data/users`, formData)
      .then((res) => {
        const { data } = res.data;
        resolve(data);
      })
      .catch(parseErrorResponse)
      .then((err) => {
        reject(err);
      });
  });

const uploadFile = (data) =>
  new Promise((resolve, reject) => {
    uploadFileS3(data.body)
      .then((file) => {
        resolve(file);
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const UploadService = {
  uploadFile,
};

export default UploadService;
