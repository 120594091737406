import React from "react";
import ActiveBids from "../components/ActiveBids";

export default function AcitveBidsPage() {
  return (
    <>
      <ActiveBids />
    </>
  );
}
