import React from "react";
import History from "../components/Transaction";

export default function HistoryPage() {
  return (
    <>
      <History />
    </>
  );
}
