import { GlobalProvider } from "contexts/GlobalContext";
import ReactDOM from "react-dom/client";
import "react-phone-input-2/lib/style.css";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "react-simple-tree-menu/dist/main.css";
import App from "./App";
import "./index.css";
import store from "./store/store";

const root = document.getElementById("root");
ReactDOM.createRoot(root).render(
  <BrowserRouter>
    <GlobalProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </GlobalProvider>
  </BrowserRouter>
);
