import React from "react";
import UpdatePassword from "components/AuthPages/UpdatePassword";

export default function UpdatePasswordPages() {
  return (
    <>
      <UpdatePassword />
    </>
  );
}
