import DataIteration from "../Helpers/DataIteration";
import SectionTitle from "../Helpers/SectionTitle";
import Package from "./Package";

export default function Packages({ data }) {
  return (
    <div className="mb-10">
      <SectionTitle name="Investment packages" />
      <div className="grid sm:grid-cols-2 grid-cols-1 2xl:grid-cols-6 gap-[30px] mt-3">
        <DataIteration datas={data} startLength={0} endLength={data?.length}>
          {({ datas }) => (
            <Package
              key={datas.id}
              name={datas.name}
              percent={datas.percent}
              icon={datas.icon}
              value={datas.value}
            />
          )}
        </DataIteration>
      </div>
    </div>
  );
}
